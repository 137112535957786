import { defineMessages } from 'react-intl';

const messages = defineMessages({
  createError: { id: 'gofre.PaymentReminders.Create.create-error' },
  createSuccess: { id: 'gofre.PaymentReminders.Create.create-success' },
  fetchingError: { id: 'gofre.PaymentReminders.fetchingError' },
  updateSuccess: { id: 'gofre.PaymentReminders.Update.update-success' },
  updateError: { id: 'gofre.PaymentReminders.updateError' },
  deleteError: { id: 'gofre.PaymentReminders.deleteError' },
  deleteSuccess: { id: 'gofre.PaymentReminders.deleteSuccess' },
});

export default messages;
