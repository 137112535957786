import * as Sentry from '@sentry/react';
import axios from 'axios';
import { assignIn, capitalize } from 'lodash';
import { v4 as uuidv4 } from 'uuid';

import { AppConfig, getConfig } from 'modules/App/config';
import { fetchBusinesses } from 'modules/Business/Change/services';
import featureFlags from 'modules/FeatureFlags';
import { Coupon } from 'modules/Subscriptions/Create/types';
import { Plan } from 'modules/Subscriptions/models';
import {
  fetchSubscriptionsPlans,
  ChangePlanResponse,
} from 'modules/Subscriptions/services';
import fetchMe from 'modules/Users/services/fetchMe';
import isNativeApp, { getDeviceInfo } from 'utils/native/isNativeApp';

import { CreatedBusiness } from '../../Business/models/business';
import {
  checkoutMapper,
  orderMapper,
  productMapper,
} from '../helpers/analyticsMappers';
import { AnalyticsEvent } from '../models';
import { AnalyticsCheckoutStep, AnalyticsFormError } from '../models/analytics';
import fetchCurrentBusinessesAnalytics from './fetchCurrentBusinessesAnalytics';

export class AnalyticsService {
  apiKey: string;

  amplitudeSessionId = Date.now();

  sessionId = uuidv4();

  userId = '';

  businessId = '';

  email = '';

  order_id = '';

  constructor(apiKey = '') {
    this.apiKey = apiKey;
    getConfig().then((config) => {
      if (!AnalyticsService.hasAnalytics(config)) {
        window.analytics = {
          identify: () => {},
          track: () => {},
          page: () => {},
        } as any;
      }
    });
    this.reset();
  }

  static hasAnalytics(config: AppConfig) {
    return (
      // disabled by config
      config.enableAnalytics === 'true' &&
      // disabled by internal prod
      (config.environment !== 'production' ||
        (config.environment === 'production' &&
          window.location.host.indexOf(config.host) !== -1)) &&
      // disabled by user config
      !(window as any).doNotTrack &&
      !navigator.doNotTrack
    );
  }

  static getTraitsBusiness() {
    const traits: any = analytics.user
      ? analytics.user().traits()
      : { vatNumber: '' };
    return JSON.parse(traits.business || '{}');
  }

  static isNativeApp(config: AppConfig) {
    return (
      AnalyticsService.hasAnalytics(config) &&
      isNativeApp() &&
      window.ReactNativeWebView.postMessage
    );
  }

  getIntegrations(integrations: Object = {}) {
    return {
      ...integrations,
      Amplitude: {
        session_id: this.amplitudeSessionId,
      },
    };
  }

  async getOptions(options: any = {}): Promise<any> {
    const config = await getConfig();
    const device = getDeviceInfo();
    const traits = assignIn(
      analytics.user ? analytics.user().traits() : { vatNumber: '' },
      options.context?.traits || {}
    );
    return {
      ...options,
      integrations: this.getIntegrations(options.integrations),
      context: {
        ...options.context,
        app: {
          name: config.name,
          version: config.version,
          namespace: config.environment,
        },
        screen: {
          density: window.devicePixelRatio,
          width: window.screen.width,
          height: window.screen.height,
        },
        device: device && {
          id: device.uniqueId,
          manufacturer: device.manufacturer,
          model: device.deviceId,
          type: device.systemName,
          name: device.device,
          token: device.token,
          version: device.fingerprint,
        },
        userId: this.userId,
        email: this.email,
        userAgent: navigator.userAgent,
        traits,
        sessionId: this.getRequestId(),
      },
    };
  }

  static getFlags() {
    const flags = featureFlags.getFlags();
    const initValue = {};

    // Note: Some providers (Appcues) don't like boolean values, so we stringify them
    return Object.entries(flags).reduce(
      (previousValue, [flagKey, flagValue]) => ({
        ...previousValue,
        [`flag${capitalize(flagKey)}`]: `${flagValue}`,
      }),
      initValue
    );
  }

  getRequestId() {
    return btoa(
      JSON.stringify({
        integrations: this.getIntegrations(),
        sessionId: this.sessionId,
        // send host to backend
        host: window.location.host,
      })
    );
  }

  // eslint-disable-next-line class-methods-use-this
  reset() {
    // last chance to queued events to send traits correctly
    setTimeout(() => {
      this.amplitudeSessionId = Date.now();
      this.sessionId = uuidv4();
      this.userId = '';
      this.businessId = '';
      this.email = '';
      this.order_id = '';
      axios.defaults.headers.common['x-session-id'] = this.getRequestId();
      if (!analytics || !analytics.user || !analytics.group) return;
      analytics.user().traits({});
      analytics.group().traits({});
      Sentry.setUser(null);
    }, 0);
  }

  async load(apiKey: string) {
    const config = await getConfig();

    if (!AnalyticsService.hasAnalytics(config) || !analytics) {
      return;
    }
    analytics.load(apiKey || this.apiKey, {
      integrations: this.getIntegrations(),
    });
  }

  async identify(businessId?: string, userId?: string, email?: string) {
    if (!businessId || !analytics) return;

    const businesses = (await fetchBusinesses()).items;
    const analyticsData = await fetchCurrentBusinessesAnalytics();

    this.sessionId = uuidv4();
    this.userId = userId || this.userId;
    this.businessId = businessId;

    const business =
      businesses.find((b) => b.id === businessId) || ({} as CreatedBusiness);

    // get email from params (login case),
    // from related business users info
    // or from fetchMe request as a last chance
    this.email =
      email ||
      business.users?.find((businessUser) => businessUser.user.id === userId)
        ?.user?.email ||
      (await fetchMe()).email;

    const traits = {
      ...analyticsData,
      ...AnalyticsService.getFlags(),
    };

    const options = await this.getOptions({
      context: {
        traits,
      },
    });
    analytics.identify(businessId, traits, options);

    Sentry.setUser(traits);

    const config = await getConfig();
    if (AnalyticsService.isNativeApp(config)) {
      window.ReactNativeWebView.postMessage(
        JSON.stringify({
          event: 'identify',
          data: {
            userId: business.id,
            traits,
            options,
          },
        })
      );
    }
    // segment do not send identify events to amplitude
    // only track user logins, not refresh tokens
    if (email) {
      this.track(AnalyticsEvent.LOGIN, { email }, options);
    }
  }

  async page(params: Object = {}) {
    if (!analytics) return;
    analytics.page(params, await this.getOptions(), () => null);
  }

  async trackModal(
    action: 'open' | 'close' | 'click',
    params: { [key: string]: any } = {}
  ) {
    if (!analytics) return;
    const { type, ...rest } = params;
    let trackType: AnalyticsEvent;
    switch (action) {
      case 'open':
        trackType = AnalyticsEvent.MODAL_OPEN;
        break;
      case 'close':
        trackType = AnalyticsEvent.MODAL_CLOSE;
        break;
      case 'click':
        trackType = AnalyticsEvent.MODAL_CLICKED;
        break;
    }
    this.track(trackType, {
      type,
      action,
      params: JSON.parse(JSON.stringify(rest)),
    });
  }

  async track(
    event: AnalyticsEvent,
    properties?: Object,
    options = {} as any,
    callback?: () => void
  ) {
    if (!analytics) {
      return;
    }
    analytics.track(
      event,
      properties || {},
      await this.getOptions(options),
      callback
    );
    const config = await getConfig();
    if (AnalyticsService.isNativeApp(config)) {
      window.ReactNativeWebView.postMessage(
        JSON.stringify({
          event,
          data: {
            userId: this.businessId,
            properties,
            options,
          },
        })
      );
    }
  }

  formError(error: AnalyticsFormError) {
    this.track(AnalyticsEvent.FORM_ERROR, error);
  }

  productsList(plans: Plan[]) {
    this.track(AnalyticsEvent.PRODUCT_LIST, {
      products: plans.map(productMapper),
    });
  }

  productClick(plan: Plan) {
    this.track(AnalyticsEvent.PRODUCT_CLICK, productMapper(plan));
  }

  checkoutStart(plan: Plan, coupon?: Coupon) {
    const checkout = checkoutMapper(
      '',
      plan,
      AnalyticsService.getTraitsBusiness(),
      coupon
    );
    this.order_id = checkout.order_id;

    this.track(AnalyticsEvent.CHECKOUT_START, checkout);
    // virtual pages
    this.page({
      title: AnalyticsEvent.CHECKOUT_START,
      path: '/subscriptions/create',
    });
  }

  getCheckoutStep(step: number) {
    return {
      checkout_id: this.order_id,
      step,
      shipping_method: 'online',
      payment_method: 'stripe',
    } as AnalyticsCheckoutStep;
  }

  checkoutStepViewed(step: number) {
    this.track(AnalyticsEvent.CHECKOUT_STEP_VIEWED, this.getCheckoutStep(step));
    // virtual pages
    this.page({
      title: AnalyticsEvent.CHECKOUT_STEP_VIEWED,
      path: `/subscriptions/create/${step}`,
    });
  }

  checkoutStepCompleted(step: number) {
    this.track(
      AnalyticsEvent.CHECKOUT_STEP_COMPLETED,
      this.getCheckoutStep(step)
    );
    // virtual pages
    this.page({
      title: AnalyticsEvent.CHECKOUT_STEP_COMPLETED,
      path: `/subscriptions/create/${step}/completed`,
    });
  }

  async orderComplete(
    product: string | Plan,
    subscriptionId: string,
    coupon?: Coupon
  ) {
    let plan: Plan | undefined;
    if (typeof product === 'string') {
      const plans = await fetchSubscriptionsPlans();
      plan = plans.find((p) => p.productId === product);
      if (!plan) {
        return;
      }
    } else {
      plan = product;
    }
    const order = orderMapper(
      this.order_id,
      plan,
      AnalyticsService.getTraitsBusiness(),
      coupon
    );
    this.track(AnalyticsEvent.ORDER_COMPLETED, {
      ...order,
      checkout_id: subscriptionId,
    });
    this.order_id = '';
  }

  async commercialNotificationCtaClicked(notification: String) {
    this.track(AnalyticsEvent.COMERCIAL_NOTIFICATION_CTA_CLICKED, {
      notification,
    });
  }

  async orderChange(plan: Plan, reason: ChangePlanResponse, coupon?: Coupon) {
    if (!reason) return;
    const order = orderMapper(
      this.order_id,
      plan,
      AnalyticsService.getTraitsBusiness(),
      coupon
    );
    this.track(
      reason.isDowngrade
        ? AnalyticsEvent.ORDER_DOWNGRADE
        : AnalyticsEvent.ORDER_UPGRADE,
      {
        ...order,
        reason: reason.reason,
      }
    );
    this.order_id = '';
  }

  orderCancelled(plan: Plan) {
    const order = orderMapper(
      this.order_id,
      plan,
      AnalyticsService.getTraitsBusiness()
    );
    this.track(AnalyticsEvent.ORDER_CANCELLED, order);
    this.order_id = '';
  }

  couponEntered(coupon: string) {
    this.track(AnalyticsEvent.COUPON_ENTERED, {
      order_id: this.order_id,
      cart_id: this.order_id,
      coupon_id: coupon,
    });
  }

  couponApplied(coupon: Coupon) {
    this.track(AnalyticsEvent.COUPON_APPLIED, {
      order_id: this.order_id,
      cart_id: this.order_id,
      coupon_id: coupon.promotionCode,
      coupon_name: coupon.promotionCode,
      discount: coupon.absoluteDiscount,
    });
  }

  couponDenied(coupon: string, reason: string) {
    this.track(AnalyticsEvent.COUPON_DENIED, {
      order_id: this.order_id,
      cart_id: this.order_id,
      coupon_id: coupon,
      coupon_name: coupon,
      reason,
    });
  }

  ocrScanClick() {
    this.track(AnalyticsEvent.OCR_SCAN_CLICK);
  }

  addLogoClick() {
    this.track(AnalyticsEvent.ADD_LOGO_BANNER_CLICK);
  }

  addStockClick() {
    this.track(AnalyticsEvent.ADD_STOCK_BANNER_CLICK);
  }

  documentSent(businessId: string, identifier: string, documentId: string) {
    this.track(AnalyticsEvent.DOCUMENT_SENT, {
      businessId,
      identifier,
      documentId,
    });
  }

  downloadElectronicDocument(
    businessId: string,
    identifier: string,
    documentId: string
  ) {
    this.track(AnalyticsEvent.DOWNLOAD_ELECTRONIC_DOCUMENT, {
      businessId,
      identifier,
      documentId,
    });
  }

  downloadSoftwareKit(software?: string) {
    this.track(AnalyticsEvent.DOWNLOAD_SOFTWARE_KIT, {
      software,
    });
  }

  leadFormSubmitted(options?: {
    isNative?: boolean;
    isBookkeeper?: boolean;
    email?: string;
  }) {
    const { isNative, isBookkeeper, email } = options ?? {};
    this.track(AnalyticsEvent.LEAD_FORM_SUBMITTED, {
      isNative: !!isNative,
      isBookkeeper: !!isBookkeeper,
      email,
    });
  }

  firstDocumentCreated(options?: {
    businessId?: string;
    documentType?: string;
  }) {
    const { businessId, documentType } = options ?? {};
    this.track(AnalyticsEvent.FIRST_DOCUMENT_CREATED, {
      businessId,
      documentType,
    });
  }
}

const analyticsService = new AnalyticsService();

export default analyticsService;
