import { FC } from 'react';
import { Hidden, Visible } from 'react-grid-system';
import { useSelector } from 'react-redux';

import useTranslations from 'modules/I18n/hooks/useTranslations';
import { useOpenModal } from 'modules/Modals';
import { ModalTypes } from 'modules/Modals/constants';
import { selectHasBasicPlan } from 'modules/Subscriptions/detail/selectors';
import { Button, Text, Tooltip } from 'modules/Ui';
import { IconInfo } from 'modules/Ui/Icons';

import { PaymentReminder } from '../../models/paymentReminder';
import paymentReminder from './messages';
import { PaymentReminderDesktop } from './PaymentReminderDesktop';
import { PaymentReminderMobile } from './PaymentReminderMobile';
import { PaymentRemindersUpgrade } from './PaymentRemindersUpgrade';

interface PaymentRemindersProps {
  paymentReminders: PaymentReminder[];
}

export const PaymentReminders: FC<PaymentRemindersProps> = ({
  paymentReminders,
}) => {
  const { t } = useTranslations();
  const openModal = useOpenModal();
  const hasBasicPlan = useSelector(selectHasBasicPlan);
  return (
    <>
      <Text
        color="primary500"
        fontSize={14}
        fontWeight="600"
        lineHeight={22}
        marginBottom={hasBasicPlan ? { _: '16px', sm: '24px' } : '8px'}
        tag="h3"
        textTransform="uppercase"
      >
        {t(paymentReminder.title)}
      </Text>
      {hasBasicPlan ? (
        <PaymentRemindersUpgrade />
      ) : (
        <>
          <Text
            color="gray800"
            fontSize={14}
            lineHeight={18}
            marginBottom={{ _: '28px', sm: '32px' }}
            display="flex"
          >
            {t(paymentReminder.text)}
            <Tooltip
              id="payment-reminder-info"
              tooltipButton={<IconInfo color="brand500" size={20} />}
              tooltipContent={t(paymentReminder.moreInfo)}
            />
          </Text>
          {paymentReminders.length ? (
            <>
              <Visible xs>
                <PaymentReminderMobile paymentReminders={paymentReminders} />
              </Visible>
              <Hidden xs>
                <PaymentReminderDesktop paymentReminders={paymentReminders} />
              </Hidden>
            </>
          ) : (
            <></>
          )}
          <Button
            onClick={() =>
              openModal({
                type: ModalTypes.ADD_PAYMENT_REMINDER,
              })
            }
            variant="stroke"
            width={{ _: '100%', sm: 'initial' }}
          >
            {t(paymentReminder.addReminder)}
          </Button>
        </>
      )}
    </>
  );
};
