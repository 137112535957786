import { FC } from 'react';

import styled, { css } from 'styled-components';

import { AnalyticsEvent } from 'modules/Analytics';
import analyticsService from 'modules/Analytics/services/analyticsService';
import useTranslations from 'modules/I18n/hooks/useTranslations';
import { ROUTES } from 'modules/Subscriptions/routesMap';
import useTheme from 'modules/Theme/hooks/useTheme';
import { Box, Img, Link, Text } from 'modules/Ui';

import paymentReminder from './messages';

export const hoverEffect = css`
  color: ${(props) => props.theme.colors.brand700};
  cursor: pointer;
  text-decoration: underline;
`;

const StyledLink = styled(Link)`
  @media (hover: hover) {
    &:hover {
      ${hoverEffect}
    }
  }
  &:focus {
    ${hoverEffect}
  }
`;

export const PaymentRemindersUpgrade: FC = () => {
  const { t } = useTranslations();
  const theme = useTheme();
  return (
    <Box
      alignItems={{ sm: 'center' }}
      backgroundColor="auxiliary100"
      columnGap="12px"
      display="grid"
      gridTemplateColumns="70px 1fr"
      padding={{ _: '16px 12px', sm: '16px 20px' }}
    >
      <Img src="/assets/payment-reminder-cebo.svg" />
      <Box>
        <Text
          color="gray800"
          fontSize={14}
          fontWeight={600}
          lineHeight={16}
          marginBottom="4px"
        >
          {t(paymentReminder.upgradeTitle)}
        </Text>
        <Text fontSize={14} lineHeight={20} display="inline">
          {t(paymentReminder.upgradeDescription)}
        </Text>{' '}
        <StyledLink
          fontSize={theme.fontSizes[14]}
          lineHeight={theme.lineHeights[20]}
          onClick={() => {
            analyticsService.track(AnalyticsEvent.UPGRADE_PAYMENT_REMINDER);
          }}
          to={ROUTES.SUBSCRIPTIONS_PLANS}
          display="inline"
        >
          {t(paymentReminder.upgradeLink)}
        </StyledLink>
      </Box>
    </Box>
  );
};
