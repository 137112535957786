import { AxiosResponse } from 'axios';

import { getConfig } from 'modules/App/config';
import {
  PAYMENT_REMINDERS,
  PAYMENT_REMINDERS_DOCUMENTS_QUERY,
} from 'modules/App/queries/queries';
import request from 'utils/request';

import { PaymentReminder } from '../models/paymentReminder';

const updatePaymentReminder = async ({
  id,
  ...data
}: PaymentReminder): Promise<PaymentReminder> => {
  const endpoint = (await getConfig()).apiPaymentRemindersService;

  const response = await request<AxiosResponse<PaymentReminder>>(
    `${endpoint}/${id}`,
    {
      method: 'PUT',
      data,
    }
  );

  return {
    ...response.data.data,
  };
};

export const invalidateQueries = [
  PAYMENT_REMINDERS,
  PAYMENT_REMINDERS_DOCUMENTS_QUERY,
];

export default updatePaymentReminder;
