import { FC } from 'react';

import useTranslations from 'modules/I18n/hooks/useTranslations';
import { Box, Text } from 'modules/Ui';
import { IconPlaneSend } from 'modules/Ui/Icons';

import paymentReminder from './messages';

interface PaymentReminderCreatedProps {
  days: number;
}

export const PaymentReminderCreated: FC<PaymentReminderCreatedProps> = ({
  days,
}) => {
  const { t } = useTranslations();
  const daysAfter = days >= 0;
  const daysConversion = Math.abs(days);
  const beforeOrAfter = daysAfter
    ? t(paymentReminder.daysAfter)
    : t(paymentReminder.daysBefore);
  return (
    <Box
      columnGap={{ _: '12px', sm: '8px' }}
      display="grid"
      gridTemplateColumns="24px 1fr"
      tag="span"
    >
      <IconPlaneSend color="brand500" />
      <Text color="gray800" fontSize={14} lineHeight={22} tag="span">
        <Text color="inherit" fontSize="inherit" fontWeight="600" tag="strong">
          {`${daysConversion} ${beforeOrAfter} `}
        </Text>
        {t(paymentReminder.expirationDate)}
      </Text>
    </Box>
  );
};
