import breakpoints from 'modules/Theme/breakpoints';

export interface ThemeInterface {
  baseNumber: string;
  breakpoints: {
    xl: string;
    lg: string;
    md: string;
    sm: string;
    xs: string;
  };
  colors: {
    accent400: string;
    accent400A30: string;
    accent500: string;
    auxiliary: string;
    auxiliary100: string;
    auxiliary100A30: string;
    avatarOrange: string;
    backdropDark: string;
    backdropLight: string;
    brand200: string;
    brand200A60: string;
    brand300: string;
    brand400: string;
    brand500: string;
    brand600: string;
    brand700: string;
    brand700A10: string;
    confirmation: string;
    confirmationAccesible: string;
    confirmationBackground: string;
    diamaconColor: string;
    error: string;
    error100A10: string;
    error100A20: string;
    errorBackground: string;
    gray0: string;
    gray200: string;
    gray300: string;
    gray400: string;
    gray400A15: string;
    gray500: string;
    gray600: string;
    gray700: string;
    gray800: string;
    gray900: string;
    neutral: string;
    pending: string;
    primary100: string;
    primary200: string;
    primary300: string;
    primary400: string;
    primary500: string;
    rejected: string;
    shadow: string;
    warning: string;
    warningBackground: string;
    gipuzkoaLogoEnabledColor: string;
    bizkaiaLogoEnabledColor: string;
    alavaLogoEnabledColor: string;
    stateNew: string;
    stateNewBg: string;
    stateBg: string;
    tagsTP100: string;
    tagsConfirmationA10: string;
    tagsConfirmationAccesible: string;
  };
  fontSizes: {
    /** 8px */ 8: string;
    /** 9px */ 9: string;
    /** 10px */ 10: string;
    /** 11px */ 11: string;
    /** 12px */ 12: string;
    /** 13px */ 13: string;
    /** 14px */ 14: string;
    /** 15px */ 15: string;
    /** 16px */ 16: string;
    /** 18px */ 18: string;
    /** 20px */ 20: string;
    /** 21px */ 21: string;
    /** 22px */ 22: string;
    /** 24px */ 24: string;
    /** 24px */ 26: string;
    /** 28px */ 28: string;
    /** 32px */ 32: string;
    /** 36px */ 36: string;
    /** 40px */ 40: string;
    /** 48px */ 48: string;
    /** 64px */ 64: string;
    /** 80px */ 80: string;
  };
  lineHeights: {
    /** 10px */ 10: string;
    /** 11px */ 11: string;
    /** 12px */ 12: string;
    /** 13px */ 13: string;
    /** 14px */ 14: string;
    /** 15px */ 15: string;
    /** 16px */ 16: string;
    /** 17px */ 17: string;
    /** 18px */ 18: string;
    /** 19px */ 19: string;
    /** 20px */ 20: string;
    /** 21px */ 21: string;
    /** 22px */ 22: string;
    /** 24px */ 24: string;
    /** 26px */ 26: string;
    /** 28px */ 28: string;
    /** 30px */ 30: string;
    /** 32px */ 32: string;
    /** 34px */ 34: string;
    /** 36px */ 36: string;
    /** 38px */ 38: string;
    /** 40px */ 40: string;
    /** 42px */ 42: string;
    /** 46px */ 46: string;
    /** 48px */ 48: string;
    /** 50px */ 50: string;
    /** 56px */ 56: string;
    /** 52px */ 52: string;
    /** 70px */ 70: string;
    /** 97px */ 97: string;
  };
  default: {
    primary: string;
    componentBackground: string;
    componentBackgroundSecondary: string;
  };
  space: number[];
  maxWidth: {
    xl: string;
    lg: string;
    md: string;
    sm: string;
    xs: string;
  };
  mobileHeader: string;
  pxToRem: (size: number) => string;
  spacing: (...args: Array<number | string>) => string;
}

// Set what's the font-size on the html element.
// 16px is the default font-size used by browsers.
const htmlFontSize = 16;

export const pxToRem = (size: number) => `${size / htmlFontSize}rem`;

const spacing = (...args: Array<number | string>): string => {
  if (process.env.NODE_ENV !== 'production') {
    if (args.length > 4) {
      throw new Error(
        `Too many arguments provided, expected between 0 and 4, got ${args.length}`
      );
    }
  }

  if (args.length === 0) {
    // eslint-disable-next-line no-param-reassign
    args[0] = 1;
  }

  return args
    .map((argument) =>
      typeof argument === 'number' && argument > 0
        ? `${argument * 8}px`
        : argument
    )
    .join(' ');
};

const theme: ThemeInterface = {
  baseNumber: '8px',
  mobileHeader: '56px',
  breakpoints: {
    xl: `${breakpoints.xl}px`,
    lg: `${breakpoints.lg}px`,
    md: `${breakpoints.md}px`,
    sm: `${breakpoints.sm}px`,
    xs: `${breakpoints.xs}px`,
  },
  colors: {
    accent400: '#FFC12B',
    accent400A30: '#FFECBF',
    accent500: '#FF9900',
    auxiliary: '#F9F8F7',
    auxiliary100: '#F4F8FD',
    auxiliary100A30: '#F8F9FA',
    avatarOrange: '#FF6E43',
    backdropDark: '#16243580',
    backdropLight: '#FFFFFFBF',
    brand200: '#CCE6FF',
    brand200A60: '#E0F0FF',
    brand300: '#99CCFF',
    brand400: '#6599FF',
    brand500: '#3366FF',
    brand600: '#1746D0',
    brand700: '#12209A',
    brand700A10: '#E8ECFA',
    confirmation: '#01C67A',
    confirmationAccesible: '#048066',
    confirmationBackground: '#DFF2E9',
    diamaconColor: '#1C100B',
    error: '#FF3A00',
    error100A10: '#FFECE6',
    error100A20: '#FBD2C9',
    errorBackground: '#F9E4DD',
    gray0: '#FFFFFF',
    gray200: '#EAEAEA',
    gray300: '#CDCDCD',
    gray400: '#AFAFAF',
    gray400A15: '#E8E8E8',
    gray500: '#919191',
    gray600: '#727272',
    gray700: '#545454',
    gray800: '#363636',
    gray900: '#0F0F0F',
    neutral: '#C4C4C4',
    pending: '#AB5C01',
    primary100: '#E7E9ED',
    primary200: '#DCDEE1',
    primary300: '#8C90A2',
    primary400: '#414F62',
    primary500: '#162435',
    rejected: '#C82200',
    shadow: '#CDCDCD80',
    warning: '#FFC12B',
    warningBackground: '#FFF8E9',
    gipuzkoaLogoEnabledColor: '#004288',
    bizkaiaLogoEnabledColor: '#D10B11',
    alavaLogoEnabledColor: '#C80A63',
    stateNew: '#5C449C',
    stateNewBg: '#EBE6FD',
    stateBg: '#F0F5FF',
    tagsTP100: '#937E7A',
    tagsConfirmationA10: '#E6F9F2',
    tagsConfirmationAccesible: '#125C66',
  },
  default: {
    primary: '#fff',
    componentBackground: '#fff',
    componentBackgroundSecondary: '#fff',
  },
  fontSizes: {
    8: pxToRem(8),
    9: pxToRem(9),
    10: pxToRem(10),
    11: pxToRem(11),
    12: pxToRem(12),
    13: pxToRem(13),
    14: pxToRem(14),
    15: pxToRem(15),
    16: pxToRem(16),
    18: pxToRem(18),
    20: pxToRem(20),
    21: pxToRem(21),
    22: pxToRem(22),
    24: pxToRem(24),
    26: pxToRem(26),
    28: pxToRem(28),
    32: pxToRem(32),
    36: pxToRem(36),
    40: pxToRem(40),
    48: pxToRem(48),
    64: pxToRem(64),
    80: pxToRem(80),
  },
  lineHeights: {
    10: pxToRem(10),
    11: pxToRem(11),
    12: pxToRem(12),
    13: pxToRem(13),
    14: pxToRem(14),
    15: pxToRem(15),
    16: pxToRem(16),
    17: pxToRem(17),
    18: pxToRem(18),
    19: pxToRem(19),
    20: pxToRem(20),
    21: pxToRem(21),
    22: pxToRem(22),
    24: pxToRem(24),
    26: pxToRem(26),
    28: pxToRem(28),
    30: pxToRem(30),
    32: pxToRem(32),
    34: pxToRem(34),
    36: pxToRem(36),
    38: pxToRem(38),
    40: pxToRem(40),
    42: pxToRem(42),
    46: pxToRem(46),
    48: pxToRem(48),
    50: pxToRem(50),
    56: pxToRem(56),
    52: pxToRem(52),
    70: pxToRem(70),
    97: pxToRem(97),
  },
  space: [0, 4, 8, 16, 24, 32, 40, 64, 128, 256, 512],
  maxWidth: {
    xl: '1270px',
    lg: '1270px',
    md: '1208px',
    sm: '959px',
    xs: '736px',
  },
  pxToRem,
  spacing,
};

export default theme;
