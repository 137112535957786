import { LayoutProps, FlexboxProps, SpaceProps } from 'styled-system';

import { HTMLInputElementLayoutProps } from 'modules/Ui/Html/Input';

import Description from '../Commons/Description';
import Label from '../Commons/Label';
import LabelText from '../Commons/LabelText';
import SuggestionsInput from '../Commons/SuggestionsInput';
import { SuggestionsData } from '../Commons/SuggestionsInput/SuggestionsInput';

interface TextAreaSuggestionsProps
  extends HTMLInputElementLayoutProps,
    LayoutProps,
    FlexboxProps,
    SpaceProps {
  rows?: number;
  error?: string;
  label: string;
  labelHidden?: boolean;
  maxLength?: number;
  helpText?: string;
  data: SuggestionsData[];
  singleLine?: boolean;
}

const TextAreaSuggestions = (props: TextAreaSuggestionsProps) => {
  const {
    disabled,
    error,
    id,
    label,
    labelHidden,
    margin,
    order,
    required,
    width,
    helpText,
    data,
    singleLine = false,
    rows,
  } = props;
  return (
    <Label id={id} margin={margin} order={order} width={width}>
      <LabelText
        hidden={labelHidden}
        {...{ id: `label-${id}`, required, disabled }}
      >
        {label}
      </LabelText>
      <SuggestionsInput
        id={id as string}
        data={data}
        rows={rows}
        singleLine={singleLine}
        maxLength={props.maxLength}
      />
      <Description id={`description-${id}`} hasError={!!error}>
        {error ?? helpText}
      </Description>
    </Label>
  );
};

export default TextAreaSuggestions;
