import { FC } from 'react';

import { typography, TypographyProps, layout } from 'styled-system';

import styled from 'modules/Theme/styled-components';
import AutosizeInput from 'modules/Ui/AutosizeInput/AutosizeInput';
import HtmlInput, { HTMLInputElementLayoutProps } from 'modules/Ui/Html/Input';

import inputStyles from '../styles/inputStyles';

const StyledInput = styled(HtmlInput)`
  ${layout}
  ${inputStyles}
  ${typography}
  ${({ type }) =>
    type === 'search' &&
    `
      -webkit-appearance: textfield;
      outline-offset: -2px;
      ::-webkit-search-decoration {
        -webkit-appearance: none;
      }

      &:hover,
      &:focus {
        &::-webkit-search-decoration,
        &::-webkit-search-cancel-button,
        &::-webkit-search-results-button,
        &::-webkit-search-results-decoration {
          display: none;
        }
      }
    `}

  ${({ type }) =>
    type === 'number' &&
    `
      -moz-appearance:textfield;
      &::-webkit-inner-spin-button,
      &::-webkit-outer-spin-button {
        -webkit-appearance: none;
        height: auto;
        margin: 0;
      }
    `}

  ${({ type }) =>
    type === 'date' &&
    `
      &::-webkit-inner-spin-button,
      &::-webkit-calendar-picker-indicator {
        display: none;
        -webkit-appearance: none;
      }
      white-space: nowrap;
  `}

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0px 1000px ${(props) => props.theme.colors.gray0}
      inset !important;
  }
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
`;
interface InputProps {
  error?: string;
  helpText?: string;
  maxLength?: number;
  hasIcon?: boolean;
  autoSize?: boolean;
  iconPosition?: 'start' | 'end';
}

const Input: FC<InputProps & HTMLInputElementLayoutProps & TypographyProps> = (
  props
) => {
  const {
    disabled,
    error,
    helpText,
    id,
    required,
    maxLength,
    type = 'text',
    textAlign,
    autoSize,
    iconPosition,
    ...rest
  } = props;

  return autoSize ? (
    <AutosizeInput />
  ) : (
    <StyledInput
      aria-describedby={error || helpText ? `description-${id}` : undefined}
      aria-invalid={error ? true : undefined}
      color={disabled && 'primary300'}
      disabled={disabled}
      error={error}
      gridArea={iconPosition === 'start' ? '1 / 2 / 3 / 3' : undefined}
      helpText={helpText}
      id={id}
      maxLength={maxLength}
      required={required}
      textAlign={textAlign}
      type={type}
      {...rest}
    />
  );
};

export default Input;
