import { max } from 'modules/Theme/breakpoints';
import { backdrop, dialogPosition } from 'modules/Theme/mixins';
import { createGlobalStyle } from 'modules/Theme/styled-components';

const Reset = createGlobalStyle`
  * {
    font-family: 'Inter', 'Arial', sans-serif;
  }

  :root {
    @supports (interpolate-size: allow-keywords) {
    /* stylelint-disable-next-line property-no-unknown */
      interpolate-size: allow-keywords;
    }
  }

  .print-only {
    display: none;
  }

  @media print {
    .no-print {
      display: none;
    }

    .print-only {
      display: block;
    }
  }

  html {
    font-family: sans-serif;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    height: 100vh;
    /* stylelint-disable-next-line unit-no-unknown */
    height: 100dvh;
    // scroll-behavior: smooth;

    @media ${max(767)} {
      // Browsers which partially support CSS Environment variables (iOS 11.0-11.2).
      @supports (padding-top: constant(safe-area-inset-top)) {
        --safe-area-inset-top: constant(safe-area-inset-top);
        height: calc(100vh + var(--safe-area-inset-top));
        /* stylelint-disable-next-line unit-no-unknown */
        height: calc(100dvh + var(--safe-area-inset-top));
      }

      // Browsers which fully support CSS Environment variables (iOS 11.2+).
      @supports (padding-top: env(safe-area-inset-top)) {
        --safe-area-inset-top: env(safe-area-inset-top);
        height: calc(100vh + var(--safe-area-inset-top));
        /* stylelint-disable-next-line unit-no-unknown */
        height: calc(100dvh + var(--safe-area-inset-top));
      }
    }
  }

  body {
    margin: 0;
    @media ${max(767)} {
      background-color: ${(props) => props.theme.colors.gray0};
      height: fill-available;
      height: webkit-fill-available;
    }
    background-color: ${(props) => props.theme.colors.auxiliary};
    text-rendering: geometricPrecision;
    @media print {
      background-color: ${(props) => props.theme.colors.gray0};
    }
  }

  b,
  strong {
    font-weight: 600;
  }

  #root {
    @media ${max(767)} {
      height: 100vh;
      /* stylelint-disable-next-line unit-no-unknown */
      height: 100dvh;
    }
    height: 100%;
  }

  .ReactQueryDevtools {
    position: absolute;
  }

  ::before,
  ::after {
    box-sizing: inherit;
    margin: 0;
    padding: 0;
  }

  a {
    -webkit-appearance: none;
    -webkit-tap-highlight-color: transparent;
    color: ${(props) => props.theme.colors.brand500};
    background-color: transparent;
    box-sizing: border-box;
    text-decoration: none;

    &:focus,
    &:active,
    &:visited {
      outline: none;
    }

    @media (hover: hover) {
      &:hover {
        cursor: pointer;
        text-decoration: none;
      }
    }
  }

  button {
    -webkit-appearance: none;
    -webkit-border-radius: 0;
    background-color: transparent;
    border-color: transparent;
    border-style: solid;
    border-width: 1px;
    box-shadow: initial;
    box-sizing: border-box;
    font-family: inherit;
    outline: none;
    padding: 0;
    text-decoration: none;

    @media(hover : hover) {
      &:hover {
        cursor: pointer;
      }
    }

    &:disabled {
      @media(hover : hover) {
        &:hover {
          cursor: text;
        }
      }
    }

    &:active,
    &:visited,
    &:focus {
      outline: none;
    }

    ::first-letter {
      text-transform: uppercase;
    }
  }

  ol,
  ul,
  dl {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  li {
    list-style: none;
  }

  dd,
  dt {
    margin: 0;
  }

  p {
    margin: 0;
    text-wrap: pretty;
  }

  [role="button"] {
    outline: none;
    @media(hover : hover) {
      &:hover {
        cursor: pointer;
      }
    }
  }

  table {
    border-collapse: collapse;
    border-spacing: 0;
  }

  [hidden] {
    display: none;
  }

  /* stylelint-disable-next-line no-descending-specificity */
  ::-webkit-file-upload-button {
    -webkit-appearance: button;
    font: inherit;
  }

  h1,h2,h3,h4 {
    margin: 0;
    padding: 0;
    text-wrap: balance;
  }

  // Hall of shame
  .react-tooltip {
    max-width: 250px;
    &.show {
      opacity: 1 !important;
      pointer-events: none !important;
    }
    div p {
      font-weight: normal !important;
    }
    span span {
      font-weight: normal !important;
    }
  }

  abbr {
    border-bottom: none;
    text-decoration: none;
  }

  .dialog {
    ${dialogPosition}
    ${backdrop}
  }
`;

export default Reset;
