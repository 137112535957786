import { FC } from 'react';
import { Hidden } from 'react-grid-system';

import useTranslations from 'modules/I18n/hooks/useTranslations';
import { Dialog, MODAL_WIDTH_MEDIUM, useCloseModal } from 'modules/Modals';
import {
  clearMailTexts,
  revertMailTexts,
} from 'modules/PaymentReminders/helpers/mailTexts';
import useUpdatePaymentReminder from 'modules/PaymentReminders/hooks/useUpdatePaymentReminder';
import { Button, Warning } from 'modules/Ui';
import { WarningType } from 'modules/Ui/Warning/Warning';

import { messages } from '../../messages';
import PaymentReminderForm, { FormValues } from '../PaymentReminderForm';
import { EditPaymentReminderModalProps } from './EditPaymentReminderModalProps';

const EditPaymentReminderModal: FC<EditPaymentReminderModalProps> = (props) => {
  const closeModal = useCloseModal();
  const { t } = useTranslations();

  const { mutate } = useUpdatePaymentReminder();

  const onSubmit = async (values: FormValues) => {
    const daysFromValues = values.days || 0;
    const { when, body, subject, tone } = values;
    const days = when === 'before' ? -daysFromValues : daysFromValues;

    await mutate({
      subject: clearMailTexts(subject),
      body: clearMailTexts(body),
      days,
      tone,
      active: props.active,
      id: props.id,
    });

    closeModal();
  };

  return (
    <Dialog
      id="edit-payment-reminder-modal"
      title={t(messages.editPaymentReminderTitle)}
      type="fullScreen"
      underlayMarginTop="66px"
      onClose={closeModal}
      buttonPrimary={
        <Button
          type="submit"
          form="editPaymentReminderForm"
          variant="secondary"
        >
          {t(messages.acceptEdit)}
        </Button>
      }
      buttonSecondary={
        <Hidden xs>
          <Button onClick={closeModal} variant="stroke">
            {t(messages.cancel)}
          </Button>
        </Hidden>
      }
      dialogWidth={{ sm: MODAL_WIDTH_MEDIUM }}
    >
      <Warning
        type={WarningType.INFO}
        message={t(messages.info)}
        iconSize={24}
        messageFontSize={14}
        messageLineHeight={19}
      />
      <PaymentReminderForm
        formId="editPaymentReminderForm"
        onSubmit={onSubmit}
        formValues={{
          tone: props.tone,
          when: props.when,
          subject: revertMailTexts(props.subject),
          body: revertMailTexts(props.body),
          days: Math.abs(props.days || 0),
        }}
      />
    </Dialog>
  );
};

export default EditPaymentReminderModal;
