import { useInvalidateAndUpdate } from 'modules/App/hooks/useInvalidateAndUpdate';
import useNotifications from 'modules/Notifications/hooks/useNotifications';
import { ResponseError } from 'types/response-error.type';
import requestErrorHandler from 'utils/requestErrorHandler';
import { useBlockingMutation } from 'utils/useBlockingMutation';

import deletePaymentReminder, {
  invalidateQueries,
} from '../services/deletePaymentReminder';
import messages from './messages';

function useDeletePaymentReminder() {
  const invalidateAndUpdate = useInvalidateAndUpdate();
  const notifications = useNotifications();

  const { mutate } = useBlockingMutation(deletePaymentReminder, {
    onSuccess: async () => {
      invalidateAndUpdate({ invalidateQueries, isAsync: true });
      notifications.success(messages.deleteSuccess.id, { ttl: 5000 });
    },
    onError: (error: ResponseError) => {
      const { isNetworkError, networkErrorTranslationKey } =
        requestErrorHandler(error);
      return notifications.error(
        isNetworkError ? networkErrorTranslationKey : messages.deleteError.id
      );
    },
  });
  return { mutate };
}

export default useDeletePaymentReminder;
