import { FC } from 'react';

import useTranslations from 'modules/I18n/hooks/useTranslations';
import { DialogConfirm, useCloseModal } from 'modules/Modals';
import useDeletePaymentReminder from 'modules/PaymentReminders/hooks/useDeletePaymentReminder';

import messages from '../AddPaymentReminderList/messages';
import { DeletePaymentReminderModalProps } from './DeletePaymentReminderModalProps';

export const DeletePaymentReminderModal: FC<
  DeletePaymentReminderModalProps
> = ({ id }) => {
  const { mutate } = useDeletePaymentReminder();
  const { t } = useTranslations();
  const closeModal = useCloseModal();
  const handleConfirm = async () => {
    mutate(id);
    closeModal();
  };
  return (
    <DialogConfirm
      id="deleteClientBookkeeper"
      title={t(messages.deleteModalTitle)}
      subtitle={t(messages.deleteModalSubtitle)}
      text={t(messages.deleteModalText)}
      onConfirm={handleConfirm}
      onCancel={closeModal}
    />
  );
};
