import { FC, useState } from 'react';
import { Hidden } from 'react-grid-system';
import { useSelector } from 'react-redux';

import styled from 'styled-components';

import { selectIsUltraWide } from 'modules/App/store/selectors';
import useTranslations from 'modules/I18n/hooks/useTranslations';
import { useOpenModal } from 'modules/Modals';
import { ModalTypes } from 'modules/Modals/constants';
import useTheme from 'modules/Theme/hooks/useTheme';
import { Text, Box, Button, OnlyIconButton } from 'modules/Ui';
import { IconTrash } from 'modules/Ui/Icons';

import { PaymentReminder, ToneEnum } from '../../models/paymentReminder';
import { ActiveToggle } from './ActiveToggle';
import messages from './messages';
import { PaymentReminderCreated } from './PaymentReminderCreated';

interface PaymentsReminderRowProps {
  paymentReminder: PaymentReminder;
}

const StyledTd = styled(Box)``;

StyledTd.defaultProps = {
  margin: '0',
  paddingBottom: '16px',
  paddingRight: { sm: '16px', md: '24px' },
  paddingTop: '16px',
  verticalAlign: { _: 'top', md: 'middle' },
};

export const PaymentReminderRow: FC<PaymentsReminderRowProps> = ({
  paymentReminder,
}) => {
  const theme = useTheme();
  const openModal = useOpenModal();
  const [active, setActive] = useState(!!paymentReminder.active);
  const { t } = useTranslations();
  const isUltraWide = useSelector(selectIsUltraWide);

  const handleEdit = () => {
    openModal({
      type: ModalTypes.EDIT_PAYMENT_REMINDER,
      tone: paymentReminder.tone || ToneEnum.FRIENDLY,
      when: paymentReminder.days < 0 ? 'before' : 'after',
      subject: paymentReminder.subject,
      body: paymentReminder.body,
      days: paymentReminder.days,
      id: paymentReminder.id,
      active: paymentReminder.active,
    });
  };

  const handleDelete = () => {
    openModal({
      type: ModalTypes.DELETE_PAYMENT_REMINDER,
      id: paymentReminder.id,
    });
  };

  const reminderActiveLabel = active
    ? t(messages.active)
    : t(messages.noActive);
  return (
    <Box borderBottom={`1px solid ${theme.colors.gray200}`} tag="tr">
      <StyledTd tag="td" paddingLeft={{ md: '8px' }}>
        <PaymentReminderCreated days={paymentReminder.days} />
      </StyledTd>
      <StyledTd
        color="gray600"
        fontSize={14}
        lineHeight={16}
        maxWidth={{ sm: '312px', lg: '526px' }}
        tag="td"
      >
        <Text fontSize="inherit" lineHeight="inherit" hasEllipsis={isUltraWide}>
          {paymentReminder.body
            .replaceAll('{{contactName}}', 'NombreCliente')
            .replaceAll('{{identifier}}', 'NumeroDocumento')
            .replaceAll('{{fiscalName}}', 'NombreFiscalEmisor')
            .replaceAll('{{dueDate}}', 'FechaVencimiento')}
        </Text>
      </StyledTd>
      <StyledTd
        tag="td"
        paddingTop={{ sm: '24px', md: '16px' }}
        paddingRight={{ md: '8px' }}
        minWidth="114px"
      >
        <Box
          alignItems={{ sm: 'center' }}
          columnGap={{ sm: '8px' }}
          display={{ md: 'grid' }}
          gridTemplateColumns={{ md: '48px 1fr' }}
          tag="span"
        >
          <ActiveToggle
            active={active}
            setActive={setActive}
            paymentReminder={paymentReminder}
          />
          <Hidden xs sm md>
            <Text
              color={active ? 'brand500' : 'gray400'}
              fontSize={12}
              lineHeight={16}
              tag="span"
              textTransform="uppercase"
            >
              {reminderActiveLabel}
            </Text>
          </Hidden>
        </Box>
      </StyledTd>
      <StyledTd
        paddingRight={{ sm: '8px' }}
        paddingTop={{ sm: '24px', md: '16px' }}
        paddingLeft={{ sm: '8px', md: 'inital' }}
        tag="td"
      >
        <Button variant="link" onClick={handleEdit} textDecoration="none">
          {t(messages.edit)}
        </Button>
      </StyledTd>
      <StyledTd tag="td">
        <OnlyIconButton
          ariaLabel={t(messages.delete)}
          icon={<IconTrash color="brand500" position="relative" />}
          size={40}
          onClick={handleDelete}
        />
      </StyledTd>
    </Box>
  );
};
