import { FC } from 'react';

import styled from 'styled-components';

import useTranslations from 'modules/I18n/hooks/useTranslations';
import { Box, Text } from 'modules/Ui';

import { PaymentReminder } from '../../models/paymentReminder';
import paymentReminder from './messages';
import { PaymentReminderRow } from './PaymentReminderRow';

interface PaymentReminderDesktopProps {
  paymentReminders: PaymentReminder[];
}

const StyledHideText = styled(Text)`
  font-size: initial;
  line-height: initial;
  color: initial;
`;

const StyledTh = styled(Box)`
  color: ${(props) => props.theme.colors.gray800};
  font-size: ${(props) => props.theme.fontSizes[12]};
  line-height: ${(props) => props.theme.lineHeights[22]};
  border-bottom: 1px solid ${(props) => props.theme.colors.primary100};
`;

export const PaymentReminderDesktop: FC<PaymentReminderDesktopProps> = ({
  paymentReminders,
}) => {
  const { t } = useTranslations();
  return (
    <Box marginBottom="24px" paddingTop="24px" tag="table" width="100%">
      <thead>
        <tr>
          <StyledTh tag="th" scope="col">
            {t(paymentReminder.tableHeader01)}
          </StyledTh>
          <StyledTh tag="th" scope="col">
            {t(paymentReminder.tableHeader02)}
          </StyledTh>
          <StyledTh className="text-hide" tag="th" scope="col">
            <StyledHideText hide tag="span">
              {t(paymentReminder.tableHeader03)}
            </StyledHideText>
          </StyledTh>
          <StyledTh tag="th" scope="col">
            <StyledHideText hide tag="span">
              {t(paymentReminder.tableHeader04)}
            </StyledHideText>
          </StyledTh>
          <StyledTh tag="th" scope="col">
            <StyledHideText hide tag="span">
              {t(paymentReminder.tableHeader05)}
            </StyledHideText>
          </StyledTh>
        </tr>
      </thead>
      <tbody>
        {paymentReminders?.length &&
          paymentReminders.map((reminder) => (
            <PaymentReminderRow
              key={`${reminder.id}-${reminder.days}`}
              paymentReminder={reminder}
            />
          ))}
      </tbody>
    </Box>
  );
};

StyledTh.defaultProps = {
  fontWeight: 'normal',
  paddingBottom: '8px',
  textAlign: 'left',
  textTransform: 'uppercase',
};
