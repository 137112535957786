import { FC } from 'react';
import ReactMarkdown from 'react-markdown';
import { useSelector } from 'react-redux';

import { selectIsMobile } from 'modules/App/store/selectors';
import useTranslations from 'modules/I18n/hooks/useTranslations';
import { MODAL_WIDTH_SMALL, Dialog, useCloseModal } from 'modules/Modals';
import breakpoints, { min } from 'modules/Theme/breakpoints';
import styled, { css } from 'modules/Theme/styled-components';
import { Button } from 'modules/Ui';

import { suggestionsInfoModalMessages } from '../../messages';

export const textStyles = css`
  color: ${(props) => props.theme.colors.gray600};
  font-size: ${(props) => props.theme.fontSizes[16]};
  line-height: ${(props) => props.theme.fontSizes[22]};
`;

export const Markdown = styled(ReactMarkdown)`
  p {
    ${textStyles};
    margin-bottom: 24px;
  }

  p:last-child {
    font-size: ${(props) => props.theme.fontSizes[12]};
    line-height: ${(props) => props.theme.fontSizes[16]};
  }

  strong {
    background-color:  ${(props) => props.theme.colors.brand200A60};
    border-radius: 3px;
    color: ${(props) => props.theme.colors.gray800};
    font-weight: normal;
    padding: 4px 8px;
  }

  ul {
    @media ${min(breakpoints.sm)} {
      padding-bottom: 16px;
    }

    li {
      ${textStyles}
      margin-bottom: 32px;

      @media ${min(breakpoints.sm)} {
        margin-bottom: 24px;
      }

      em {
        display: block;
        margin-top: 8px;
        font-style: normal;
        @media ${min(breakpoints.sm)} {
          margin-top: 0px;
          display: initial;
        }
      }
    }
  }
`;

const SuggestionInfoModal: FC = () => {
  const { t } = useTranslations();
  const handleClose = useCloseModal();
  const isMobile = useSelector(selectIsMobile);
  return (
    <Dialog
      type="fullScreen"
      title={
        isMobile
          ? t(suggestionsInfoModalMessages.titleMobile)
          : t(suggestionsInfoModalMessages.title)
      }
      content={
        <Markdown>{t(suggestionsInfoModalMessages.contentNew)}</Markdown>
      }
      dialogWidth={{ md: MODAL_WIDTH_SMALL }}
      mobileBorderHeader
      onClose={handleClose}
      buttonPrimary={
        <Button
          data-testid="accept-dialog-button"
          onClick={handleClose}
          type="button"
          variant="secondary"
        >
          {t(suggestionsInfoModalMessages.button)}
        </Button>
      }
    />
  );
};

export default SuggestionInfoModal;
