import { ChangeEvent, FC } from 'react';

import useUpdatePaymentReminder from 'modules/PaymentReminders/hooks/useUpdatePaymentReminder';
import { PaymentReminder } from 'modules/PaymentReminders/models/paymentReminder';
import { Toggle } from 'modules/Ui';

interface ActiveToggleProps {
  paymentReminder: PaymentReminder;
  active: boolean;
  setActive: (active: boolean) => void;
}

export const ActiveToggle: FC<ActiveToggleProps> = ({
  paymentReminder,
  active,
  setActive,
}) => {
  const { mutate } = useUpdatePaymentReminder();

  const onToggleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const activeStatus = event.target.checked;
    setActive(activeStatus);
    mutate({ ...paymentReminder, active: activeStatus });
  };
  return (
    <Toggle
      id={`payments-reminder-active-${paymentReminder.id}`}
      value={active}
      name={`payments-reminder-active-${paymentReminder.id}`}
      onChange={onToggleChange}
    />
  );
};
