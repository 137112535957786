import { defineMessages } from 'react-intl';

export default defineMessages({
  title: { id: 'gofre.Plans.Change.title' },
  confirm: { id: 'gofre.Plans.Change.confirm' },
  cancel: { id: 'gofre.Modals.cancel' },
  continue: { id: 'gofre.Modals.continue' },
  wrongPeriodicity: { id: 'gofre.Plans.Change.wrong-periodicity' },
  wrongPeriodicityB: { id: 'gofre.Plans.Change.wrong-periodicity-b' },
  samePlan: { id: 'gofre.Plans.Change.same-plan' },
  samePlanB: { id: 'gofre.Plans.Change.same-plan-b' },
  downgrade: { id: 'gofre.Plans.Change.to-lower' },
  downgradeB: { id: 'gofre.Plans.Change.to-lower-b' },
  genericScopeError: { id: 'gofre.Plans.Change.generic-scope-error' },
  downgradeNotAllowedB: { id: 'gofre.Plans.Change.to-lower-not-allowed-b' },
  maxChangesReached: { id: 'gofre.Plans.Change.max-plan-changes-reached' },
  maxChangesReachedB: { id: 'gofre.Plans.Change.max-plan-changes-reached-b' },
  recurrents: { id: 'gofre.Plans.Change.recurrents-not-allowed-in-plan' },
  recurrents01: { id: 'gofre.Plans.Change.recurrents-not-allowed-in-plan-01' },
  expenseRecurrents: {
    id: 'gofre.Plans.Change.recurrent-expenses-not-allowed-in-plan',
  },
  expenseRecurrents01: {
    id: 'gofre.Plans.Change.recurrent-expenses-not-allowed-in-plan-01',
  },
  products: { id: 'gofre.Plans.Change.products-plan-reached' },
  ocr: { id: 'gofre.Plans.Change.ocr-reached' },
  shopifyTitle: { id: 'gofre.Plans.Change.wrong-shopify-plan-compatibility' },
  shopifySubtitle: {
    id: 'gofre.Plans.Change.wrong-shopify-plan-compatibility-01',
  },
  apiPublicTitle: {
    id: 'gofre.Plans.Change.wrong-api-public-plan-compatibility',
  },
  apiPublicSubtitle: {
    id: 'gofre.Plans.Change.wrong-api-public-plan-compatibility-01',
  },
  genericScopeWarningText: {
    id: 'gofre.Subscriptions.ChangePlan.GenericScopeWarning.text',
  },
  remittanceCreationWarningText: {
    id: 'gofre.Subscriptions.ChangePlan.RemittanceCreationWarning.text',
  },
  remittanceCreationWarningSubtitle: {
    id: 'gofre.Subscriptions.ChangePlan.RemittanceCreationWarning.subtitle',
  },
  productStockSubtitle: {
    id: 'gofre.Subscriptions.ChangePlan.ProductStockEnabled.subtitle',
  },
  productStockText: {
    id: 'gofre.Subscriptions.ChangePlan.ProductStockEnabled.text',
  },
  paymentRemindersTitle: {
    id: 'gofre.Subscriptions.ChangePlan.PaymentRemindersEnabled.title',
  },
  paymentRemindersSubtitle: {
    id: 'gofre.Subscriptions.ChangePlan.PaymentRemindersEnabled.subtitle',
  },
});
