import { FC } from 'react';

import useTranslations from 'modules/I18n/hooks/useTranslations';
import useTheme from 'modules/Theme/hooks/useTheme';
import { Box, Text } from 'modules/Ui';

import { PaymentReminder } from '../../models/paymentReminder';
import paymentReminder from './messages';
import { PaymentReminderItem } from './PaymentReminderItem';

interface PaymentReminderMobileProps {
  paymentReminders: PaymentReminder[];
}

export const PaymentReminderMobile: FC<PaymentReminderMobileProps> = ({
  paymentReminders,
}) => {
  const theme = useTheme();
  const { t } = useTranslations();
  return (
    <>
      <Text
        borderBottom={`1px solid ${theme.colors.primary200}`}
        color="gray800"
        fontSize={12}
        fontWeight="normal"
        paddingBottom="8px"
        tag="h4"
        textTransform="uppercase"
      >
        {t(paymentReminder.titleMobile)}
      </Text>
      <Box tag="ul" marginBottom="24px">
        {paymentReminders?.length &&
          paymentReminders.map((reminder) => (
            <PaymentReminderItem
              key={`${reminder.id}-${reminder.days}`}
              paymentReminder={reminder}
            />
          ))}
      </Box>
    </>
  );
};
