import { FC } from 'react';

import { useFeatureFlag } from 'modules/FeatureFlags';
import useTranslations from 'modules/I18n/hooks/useTranslations';
import { ModalTypes } from 'modules/Modals/constants';
import { useOpenModal } from 'modules/Modals/hooks';
import useCreatePaymentMethod from 'modules/PaymentMethods/hooks/useCreatePaymentMethod';
import useDefaultPaymentMethods from 'modules/PaymentMethods/hooks/useDefaultPaymentMethods/useDefaultPaymentMethods';
import { PaymentReminders } from 'modules/PaymentReminders/components/AddPaymentReminderList';
import { useFetchPaymentReminders } from 'modules/PaymentReminders/hooks/useFetchPaymentReminders';
import { paymentsMethod as messages } from 'modules/Settings/messages';
import PaymentMethodTable from 'modules/Settings/PaymentsMethod/components/PaymentsMethodTable';
import PaymentsMethodZeroState from 'modules/Settings/PaymentsMethod/PaymentsMethodZeroState';
import useTheme from 'modules/Theme/hooks/useTheme';
import { Text, Button, Box } from 'modules/Ui';

export const PaymentsMethod: FC = () => {
  const { t } = useTranslations();
  const { paymentMethods } = useDefaultPaymentMethods();
  const openModal = useOpenModal();
  const theme = useTheme();
  const noPaymentMethods = paymentMethods?.length === 0;
  const { mutate } = useCreatePaymentMethod({});
  const { paymentReminders } = useFetchPaymentReminders();
  const showPaymentReminders = useFeatureFlag('showPaymentsreminders');

  return (
    <Box paddingBottom={{ _: '48px', sm: '92px' }}>
      <Text
        color="gray800"
        fontSize={14}
        fontWeight={600}
        marginBottom="8px"
        tag="h3"
        textTransform="uppercase"
      >
        {t(messages.title)}
      </Text>
      <Text
        color="primary500"
        fontSize={14}
        lineHeight={18}
        marginBottom={{ _: '16px', sm: '32px' }}
      >
        {t(messages.description)}
      </Text>
      <Button
        data-testid="addPaymentMethodButton"
        marginBottom="44px"
        onClick={() => {
          openModal({
            paymentMethods: paymentMethods || [],
            type: ModalTypes.ADD_PAYMENT_METHOD,
            onSuccess: mutate,
          });
        }}
        type="button"
        variant="primary"
        width={{ _: '100%', sm: 'initial' }}
      >
        {t(messages.addPaymentMethod)}
      </Button>
      <Text
        color="gray800"
        borderBottom={
          !noPaymentMethods
            ? { _: `1px solid ${theme.colors.gray200}`, sm: 'none' }
            : undefined
        }
        fontSize={18}
        fontWeight="600"
        lineHeight={20}
        marginBottom={{ _: '6px', sm: '10px' }}
        paddingBottom={{ _: '10px', sm: '0' }}
        tag="h3"
      >
        {t(messages.zeroStateTitle)}
      </Text>
      {noPaymentMethods ? (
        <PaymentsMethodZeroState />
      ) : (
        <PaymentMethodTable paymentMethods={paymentMethods} />
      )}

      {showPaymentReminders && (
        <PaymentReminders paymentReminders={paymentReminders} />
      )}
    </Box>
  );
};
