import { css } from 'modules/Theme/styled-components';

interface SlidingCenterUnderlineProps {
  hoverColor?: string;
  hoverUnderline?: boolean;
}

const hoverStyle = css<SlidingCenterUnderlineProps>`
  @media (hover: hover) {
    &:hover {
      cursor: pointer;
      color: ${({ hoverColor }) => hoverColor || ''};
      text-decoration: ${({ hoverUnderline }) =>
        hoverUnderline ? 'underline' : ''};
    }
  }

  &:focus {
    color: ${({ hoverColor }) => hoverColor || ''};
    text-decoration: ${({ hoverUnderline }) =>
      hoverUnderline ? 'underline' : ''};
  }
`;

export default hoverStyle;
