import { useQuery } from 'react-query';

import { PAYMENT_REMINDERS } from 'modules/App/queries/queries';
import useNotifications from 'modules/Notifications/hooks/useNotifications';

import fetchPaymentReminders from '../services/fetchPaymentReminders';
import messages from './messages';

export const useFetchPaymentReminders = () => {
  const notifications = useNotifications();

  const { data, isFetching } = useQuery(
    PAYMENT_REMINDERS,
    () => fetchPaymentReminders(),
    {
      onError: () => {
        notifications.error(messages.fetchingError.id);
      },
    }
  );

  return {
    paymentReminders: data?.items ?? [],
    isLoading: isFetching,
  };
};
