import { FC, ReactNode } from 'react';

import { space, SpaceProps, typography, TypographyProps } from 'styled-system';

import styled from 'modules/Theme/styled-components';

import Text from '../Text';

interface Props extends SpaceProps, TypographyProps {
  children: ReactNode | string;
  id?: string;
}

const StyledTitle = styled(Text)`
  ${space}
  ${typography}
`;

const SectionTitle: FC<Props> = ({ id, children, ...rest }) => (
  <StyledTitle
    color="brand500"
    data-testid={id}
    fontSize={{ _: 24, sm: 28 }}
    lineHeight={48}
    paddingTop={{ _: '8px', sm: 'initial' }}
    paddingLeft={{ _: '40px', xs: 'initial' }}
    tag="h2"
    {...rest}
  >
    {children}
  </StyledTitle>
);

StyledTitle.defaultProps = {
  marginBottom: { sm: '16px' },
  fontWeight: '600',
};

export default SectionTitle;
