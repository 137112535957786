import { defineMessages } from 'react-intl';

export const integrations = defineMessages({
  title: { id: 'gofre.integrations.title' },
  description: { id: 'gofre.integrations.description' },
  next: { id: 'gofre.integrations.next' },
  shopifysubitle: { id: 'gofre.integrations.shopify.subtitle' },
  apiSutitle: { id: 'gofre.integrations.api.subtitle' },
  apiTitle: { id: 'gofre.integrations.api.title' },
  makeSubtitle: { id: 'gofre.integrations.make.subtitle' },
});

export default defineMessages({
  shopifyTab: {
    id: 'gofre.Settings.tabs.shopify',
  },
  actiaveShopifyNotification: {
    id: 'gofre.Settings.Shopify.activate.notification',
  },
  deactiaveShopifyNotification: {
    id: 'gofre.Settings.Shopify.deactivate.notification',
  },
});

export const shopifyForm = defineMessages({
  title: {
    id: 'gofre.Settings.shopify.form.title',
  },
  subtitle: {
    id: 'gofre.Settings.shopify.form.subtitle',
  },
  seriesTitle: {
    id: 'gofre.Settings.shopify.form.serie.title',
  },
  seriesTooltip: {
    id: 'gofre.Settings.shopify.form.serie.tooltip',
  },
  seriesLabelHide: {
    id: 'gofre.Settings.shopify.form.serie.label.hide',
  },
  seriesLabelText: {
    id: 'gofre.Settings.shopify.form.serie.label.text',
  },
  seriesOptionLegend: {
    id: 'gofre.Settings.shopify.form.serie.option.legend',
  },
  seriesLabelText02: {
    id: 'gofre.Settings.shopify.form.serie.label.text02',
  },
  seriesToogle01label: {
    id: 'gofre.Settings.shopify.form.serie.toogle01.label',
  },
  seriesToogle01Desc: {
    id: 'gofre.Settings.shopify.form.serie.toogle01.desc',
  },
  seriesToogle02label: {
    id: 'gofre.Settings.shopify.form.serie.toogle02.label',
  },
  seriesToogle02Desc: {
    id: 'gofre.Settings.shopify.form.serie.toogle02.desc',
  },
  seriesDraftNotificationLegend: {
    id: 'gofre.Settings.shopify.form.serie.draftNotification.legend',
  },
  seriesToogle03label: {
    id: 'gofre.Settings.shopify.form.serie.toogle03.label',
  },
  seriesToogle03desc: {
    id: 'gofre.Settings.shopify.form.serie.toogle03.desc',
  },
  seriesCancel: {
    id: 'gofre.Settings.shopify.form.cancel',
  },
  seriesSave: {
    id: 'gofre.Settings.shopify.form.save',
  },
  ShopifyIntegrationTitleActive: {
    id: 'gofre.Settings.shopify.integration.active.title',
  },
  ShopifyIntegrationTitleNoActive: {
    id: 'gofre.Settings.shopify.integration.noactive.title',
  },
  ShopifyIntegrationInfoActive: {
    id: 'gofre.Settings.shopify.integration.active.info',
  },
  ShopifyIntegrationInfoNoActive: {
    id: 'gofre.Settings.shopify.integration.noactive.info',
  },
  ShopifyIntegrationShop: {
    id: 'gofre.Settings.shopify.integration.shop',
  },
  ShopifyIntegrationButtonActive: {
    id: 'gofre.Settings.shopify.integration.button.active',
  },
  ShopifyIntegrationButtonNoActive: {
    id: 'gofre.Settings.shopify.integration.button.noactive',
  },
  invalidTaxes: {
    id: 'gofre.Settings.shopify.form.invalidTaxes',
  },
});

export const errors = defineMessages({
  ShopifyConfigSuccess: {
    id: 'gofre.Settings.shopify.integration.success',
  },
  ShopifyConfigBaiNotConnectedWarning: {
    id: 'gofre.Settings.shopify.integration.bai-not-connected-warning',
  },
  ShopifyConfigError: {
    id: 'gofre.Settings.shopify.integration.error',
  },
  ShopifyConfigNotConnected: {
    id: 'gofre.Settings.shopify.integration.notconnected',
  },
});

export const shopifyZeroState = defineMessages({
  baiZeroStateText: {
    id: 'gofre.Settings.shopify.zero-state.bai.text',
  },
  zeroStateText: {
    id: 'gofre.Settings.shopify.zero-state.text',
  },
  zeroStateTextNotAuthorized: {
    id: 'gofre.Settings.shopify.zero-state.notAuthorized',
  },
  zeroStateTitle: {
    id: 'gofre.Settings.shopify.zero-state.title',
  },
  zeroStateUpgradeButton: {
    id: 'gofre.Settings.shopify.zero-state.upgrade.button',
  },
  zeroStateStartButton: {
    id: 'gofre.Settings.shopify.zero-state.start.button',
  },
});

export const shopifySuccess = defineMessages({
  shopifyConfigSuccessTitle: {
    id: 'gofre.Settings.shopify.integration.success.title',
  },
  shopifyConfigSuccessSubTitleConnect: {
    id: 'gofre.Settings.shopify.integration.success.subtitleConnect',
  },
  shopifyConfigSuccessSubTitleConfigure: {
    id: 'gofre.Settings.shopify.integration.success.subtitleConfigure',
  },
  shopifyConfigSuccessTextConfigureBai: {
    id: 'gofre.Settings.shopify.integration.success.textConfigureBai',
  },
  shopifyConfigSuccessTextConnectBai: {
    id: 'gofre.Settings.shopify.integration.success.textConnectBai',
  },
  shopifyConfigSuccessText: {
    id: 'gofre.Settings.shopify.integration.success.text',
  },
  shopifyConfigSuccessAlt: {
    id: 'gofre.Settings.shopify.integration.success.img-alt',
  },
  shopifyConfigSuccessButton: {
    id: 'gofre.Settings.shopify.integration.success.button',
  },
  shopifyConfigSuccessConfigureCta: {
    id: 'gofre.Settings.shopify.integration.success.configureCta',
  },
  shopifyConfigSuccessConnectCta: {
    id: 'gofre.Settings.shopify.integration.success.connectCta',
  },
  shopifyConfigSuccessConfigureLater: {
    id: 'gofre.Settings.shopify.integration.success.configureLater',
  },
  shopifyConfigSuccessConnectLater: {
    id: 'gofre.Settings.shopify.integration.success.connectLater',
  },
});

export const shopifyIntefrationSteps = defineMessages({
  title: {
    id: 'gofre.Settings.shopifyIntefrationSteps.title',
  },
  step01: {
    id: 'gofre.Settings.shopifyIntefrationSteps.step01',
  },
  step02: {
    id: 'gofre.Settings.shopifyIntefrationSteps.step02',
  },
  step03: {
    id: 'gofre.Settings.shopifyIntefrationSteps.step03',
  },
});

export const apiConfigurationIntefrationSteps = defineMessages({
  title: {
    id: 'gofre.Settings.apiConfigurationIntefrationSteps.title',
  },
  step01: {
    id: 'gofre.Settings.apiConfigurationIntefrationSteps.step01',
  },
  step02: {
    id: 'gofre.Settings.apiConfigurationIntefrationSteps.step02',
  },
  step03: {
    id: 'gofre.Settings.apiConfigurationIntefrationSteps.step03',
  },
});

export const deactivateShopifyModal = defineMessages({
  title: {
    id: 'gofre.Settings.shopifyDeactivateModal.title',
  },
  text: {
    id: 'gofre.Settings.shopifyDeactivateModal.text',
  },
  cta: {
    id: 'gofre.Settings.shopifyDeactivateModal.cta',
  },
});

export const apiPublicZeroState = defineMessages({
  zeroStateBackTitle: {
    id: 'gofre.Integrations.api.title',
  },
  zeroStateText: {
    id: 'gofre.Settings.api-public.zero-state.text',
  },
  zeroStateTitle: {
    id: 'gofre.Settings.api-public.zero-state.title',
  },
  zeroStateUpgradeButton: {
    id: 'gofre.Settings.api-public.zero-state.upgrade.button',
  },
});

export const apiConfigurationForm = defineMessages({
  title: {
    id: 'gofre.Settings.apiConfigurationForm.title',
  },
  sectionCredentials: {
    id: 'gofre.Settings.apiConfigurationForm.sectionCredentials',
  },
  sectionCredentialsDescription: {
    id: 'gofre.Settings.apiConfigurationForm.sectionCredentialsDescription',
  },
  sectionCredentialsDescriptionButton: {
    id: 'gofre.Settings.apiConfigurationForm.sectionCredentialsDescriptionButton',
  },
  sectionCredentialsGenerated: {
    id: 'gofre.Settings.apiConfigurationForm.sectionCredentialsGenerated',
  },
  sectionConfiguration: {
    id: 'gofre.Settings.apiConfigurationForm.sectionConfiguration',
  },
  sectionConfigurationDescription: {
    id: 'gofre.Settings.apiConfigurationForm.sectionConfigurationDescription',
  },
  seriesToogle01label: {
    id: 'gofre.Settings.apiConfigurationForm.seriesToogle01label',
  },
  seriesToogle01Desc: {
    id: 'gofre.Settings.apiConfigurationForm.seriesToogle01Desc',
  },
  cancel: {
    id: 'gofre.Settings.apiConfigurationForm.cancel',
  },
  submit: {
    id: 'gofre.Settings.apiConfigurationForm.submit',
  },
  credentialCreated: {
    id: 'gofre.Settings.apiConfigurationForm.credentialCreated',
  },
  credentialCreatedError: {
    id: 'gofre.Settings.apiConfigurationForm.credentialCreatedError',
  },
  credentialLimitReached: {
    id: 'gofre.Settings.apiConfigurationForm.credentialLimitReached',
  },
  credentialDeleted: {
    id: 'gofre.Settings.apiConfigurationForm.credentialDeleted',
  },
  credentialDeletedError: {
    id: 'gofre.Settings.apiConfigurationForm.credentialDeletedError',
  },
  seriesTitle: {
    id: 'gofre.Settings.apiConfigurationForm.form.serie.title',
  },
  seriesTooltip: {
    id: 'gofre.Settings.apiConfigurationForm.form.serie.tooltip',
  },
  seriesLabelHide: {
    id: 'gofre.Settings.apiConfigurationForm.form.serie.label.hide',
  },
  seriesLabelText: {
    id: 'gofre.Settings.apiConfigurationForm.form.serie.label.text',
  },
  seriesOptionLegend: {
    id: 'gofre.Settings.apiConfigurationForm.form.serie.option.legend',
  },
  seriesLabelText02: {
    id: 'gofre.Settings.apiConfigurationForm.form.serie.label.text02',
  },
  configurationSubmitSuccess: {
    id: 'gofre.Settings.apiConfigurationForm.configurationSubmitSuccess',
  },
  configurationSubmitError: {
    id: 'gofre.Settings.apiConfigurationForm.configurationSubmitError',
  },
  baiOptionLegend: {
    id: 'gofre.Settings.apiConfigurationForm.form.bai.option.legend',
  },
  baiOptionText: {
    id: 'gofre.Settings.apiConfigurationForm.form.bai.option.text',
  },
  baiOptionDescription: {
    id: 'gofre.Settings.apiConfigurationForm.form.bai.option.description',
  },
  baiOptionWarning: {
    id: 'gofre.Settings.apiConfigurationForm.form.bai.option.warning',
  },
  delete: {
    id: 'gofre.Settings.apiConfigurationForm.form.credentials.delete',
  },
});

export const apiConfigurationBanner = defineMessages({
  title: {
    id: 'gofre.Settings.apiConfigurationBanner.title',
  },
  text: {
    id: 'gofre.Settings.apiConfigurationBanner.text',
  },
  cta: {
    id: 'gofre.Settings.apiConfigurationBanner.cta',
  },
});

export const generateCredentialModal = defineMessages({
  title: {
    id: 'gofre.Settings.generateCredentialModal.title',
  },
  name: {
    id: 'gofre.Settings.generateCredentialModal.name',
  },
  namePlaceHolder: {
    id: 'gofre.Settings.generateCredentialModal.namePlaceHolder',
  },
  clientId: {
    id: 'gofre.Settings.generateCredentialModal.clientId',
  },
  clientSecret: {
    id: 'gofre.Settings.generateCredentialModal.clientSecret',
  },
  description: {
    id: 'gofre.Settings.generateCredentialModal.description',
  },
  cancel: {
    id: 'gofre.Settings.generateCredentialModal.cancel',
  },
  submit: {
    id: 'gofre.Settings.generateCredentialModal.submit',
  },
  buttonCopy: {
    id: 'gofre.Settings.generateCredentialModal.buttonCopy',
  },
});

export const deleteCredentialModal = defineMessages({
  header: {
    id: 'gofre.Settings.deleteCredentialModal.header',
  },
  title: {
    id: 'gofre.Settings.deleteCredentialModal.title',
  },
  text: {
    id: 'gofre.Settings.deleteCredentialModal.text',
  },
  cancel: {
    id: 'gofre.Settings.deleteCredentialModal.cancel',
  },
  submit: {
    id: 'gofre.Settings.deleteCredentialModal.submit',
  },
});

export const ApiConfigurationDialog = defineMessages({
  title: {
    id: 'gofre.Settings.apiConfigurationModal.title',
  },
  subtitle: {
    id: 'gofre.Settings.apiConfigurationModal.subtitle',
  },
  text: {
    id: 'gofre.Settings.apiConfigurationModal.text',
  },
  save: {
    id: 'gofre.Settings.apiConfigurationModal.save',
  },
  cancel: {
    id: 'gofre.Settings.apiConfigurationModal.cancel',
  },
});
