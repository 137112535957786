import { Visible } from 'react-grid-system';

import { CreatedPaymentMethod } from 'modules/PaymentMethods/models/paymentMethods';
import { max } from 'modules/Theme/breakpoints';
import styled from 'modules/Theme/styled-components';
import { Box } from 'modules/Ui';

import PaymentMethodHeader from './PaymentMethodHeader';
import PaymentMethodRow from './PaymentMethodRow';

interface PaymentMethodRowProps {
  paymentMethods?: CreatedPaymentMethod[];
}

const StyledTable = styled(Box)`
  @media ${max(767)} {
    .data__table {
      &--type { grid-area: type;}
      &--text { grid-area: text;}
      &--alias { display: none;}
      &--edit {align-items: center; display: flex; grid-area: edit; }
    }

    tbody tr {
      display: grid;
      gap: 0px 8px;
      grid-template-columns: 1fr auto;
      grid-template-rows: auto auto;
      grid-template-areas:
        "type edit"
        "text edit"
    }
  }
`;

const PaymentMethodTable: React.FC<PaymentMethodRowProps> = ({
  paymentMethods,
}) => {
  return (
    <StyledTable
      data-testid="paymentMethods-items"
      marginBottom="56px"
      position="relative"
      tag="table"
      width="100%"
    >
      <Visible md lg xl xxl>
        <thead>
          <PaymentMethodHeader />
        </thead>
      </Visible>
      <tbody>
        {paymentMethods?.length &&
          paymentMethods.map((method) => (
            <PaymentMethodRow
              key={`${method.id}-${method.type}`}
              paymentMethod={method}
            />
          ))}
      </tbody>
    </StyledTable>
  );
};

export default PaymentMethodTable;
