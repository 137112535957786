import {
  ReactNode,
  DetailedHTMLProps as Props,
  AnchorHTMLAttributes as Attributes,
  useCallback,
} from 'react';
import { useSelector } from 'react-redux';
import { LinkProps as RouterLinkProps } from 'react-router-dom';

import { selectIsMobile } from 'modules/App/store/selectors';
import useTranslations from 'modules/I18n/hooks/useTranslations';
import useTheme from 'modules/Theme/hooks/useTheme';
import { BoxProps } from 'modules/Ui/Box/Box';
import messages from 'modules/Ui/Form/ButtonAction/messages';

import Box from '../Box';
import ExternalLink from './LinkType/ExternalLink';
import InternalLink from './LinkType/InternalLink';

export interface LinkProps extends BoxProps {
  ariaLabel?: string;
  children?: string | ReactNode;
  hoverUnderline?: boolean;
  hoverColor?: string;
  icon?: string | ReactNode;
  iconColor?: string;
  iconSize?: number;
  iconMarginRight?: string | number;
  isExternal?: boolean;
  target?: 'blank' | 'self' | 'parent' | 'top';
  to: RouterLinkProps['to'];
  download?: string | boolean;
  variant?:
    | 'primary'
    | 'secondary'
    | 'stroke'
    | 'strokeTransparent'
    | 'link'
    | 'ghost'
    | 'ghostDark'
    | 'light';
  textDecoration?: 'underline' | 'none';
  type?: string;
}

const CustomLink = (
  props: LinkProps & Props<Attributes<HTMLAnchorElement>, HTMLAnchorElement>
) => {
  const {
    ariaLabel,
    children,
    download,
    hoverUnderline,
    hoverColor,
    icon,
    iconColor,
    iconMarginRight,
    iconSize,
    isExternal = false,
    target,
    textDecoration,
    to,
    type,
    variant,
    ...rest
  } = props;
  const destination = typeof to === 'string' ? to : undefined;
  const theme = useTheme();
  const isMobile = useSelector(selectIsMobile);
  const { t } = useTranslations();

  const linkLineHeight = useCallback(() => {
    if (isMobile) {
      if (variant) {
        return `${theme.lineHeights[46]}`;
      }
    }
    if (variant) {
      return `${theme.lineHeights[38]}`;
    }
    return `${theme.lineHeights[26]}`;
  }, [variant, icon]);
  return (
    <>
      {isExternal ? (
        <ExternalLink
          ariaLabel={ariaLabel}
          color={icon && !variant ? 'brand500' : undefined}
          display={!icon && variant ? 'inline-block' : undefined}
          hoverUnderline={hoverUnderline}
          hoverColor={hoverColor}
          href={destination}
          icon={icon}
          iconSize={iconSize || 24}
          lineHeight={linkLineHeight()}
          target={target}
          textDecoration={textDecoration}
          to={destination}
          type={type}
          variant={variant}
          {...{ ...rest }}
        >
          {children}
          {type === 'application/pdf' && (
            <Box accessibleHidden tag="span">
              {t(messages.pdf)}
            </Box>
          )}
          {target === 'blank' && (
            <Box accessibleHidden tag="span">
              {t(messages.targetBlank)}
            </Box>
          )}
        </ExternalLink>
      ) : (
        <InternalLink
          ariaLabel={ariaLabel}
          color={icon && !variant ? `${theme.colors.brand500}` : undefined}
          hoverUnderline={hoverUnderline}
          hoverColor={hoverColor}
          icon={icon}
          iconColor={iconColor}
          iconSize={iconSize || 24}
          lineHeight={linkLineHeight()}
          to={to}
          variant={variant}
          iconMarginRight={iconMarginRight}
          textDecoration={textDecoration}
          {...{
            target,
            ...rest,
          }}
        >
          {children}
        </InternalLink>
      )}
    </>
  );
};

export default CustomLink;
