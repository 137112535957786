import { FC, useCallback, ReactNode, useState, useEffect } from 'react';

import { flexbox, space, border, shadow, layout } from 'styled-system';

import styled from 'modules/Theme/styled-components';

import Box from '../Box';
import { BoxProps } from '../Box/Box';
import Button from '../Form/Button';
import { ExpandedContent } from './components/ExpandableContent';

interface Props extends BoxProps {
  content?: ReactNode;
  id?: string;
  initiallyExpanded?: boolean;
  onChange?(newExpandedState: boolean): void;
  title: string | ReactNode;
  titleAtTheEnd?: boolean;
  forceOpen?: boolean;
  titleWidth?: string;
}

const StyledWrapper = styled(Box)`
  ${border}
  ${space}
  ${flexbox}
  ${shadow}
  ${layout}

  .expandable {
    &__icon {
       transition: transform 0.10s;
    }

    &__button--open .expandable__icon {
      transform: rotate(-180deg);
    }
  }
`;

const CustomExpandable: FC<Props> = ({
  content,
  id = 'custom-expandable',
  initiallyExpanded = false,
  onChange,
  title,
  titleAtTheEnd,
  forceOpen,
  titleWidth = '100%',
  ...rest
}) => {
  const [open, setExpanded] = useState(initiallyExpanded);
  const toggleExpanded = useCallback(() => {
    if (forceOpen && open) {
      return;
    }
    setExpanded(!open);
    onChange && onChange(!open);
  }, [open, setExpanded, onChange, forceOpen]);
  useEffect(() => {
    if (forceOpen) {
      setExpanded(true);
      onChange && onChange(true);
    }
  }, [forceOpen]);
  return (
    <StyledWrapper
      display={titleAtTheEnd && 'flex'}
      flexDirection={titleAtTheEnd && 'column-reverse'}
      {...rest}
    >
      <Button
        aria-controls={`controls-${id}`}
        aria-expanded={open}
        className={
          open
            ? 'expandable__button expandable__button--open'
            : 'expandable__button'
        }
        data-testid={id}
        onClick={toggleExpanded}
        width={titleWidth}
      >
        {title}
      </Button>
      <Box id={`controls-${id}`} role="region">
        <ExpandedContent open={open} content={content} />
      </Box>
    </StyledWrapper>
  );
};

export default CustomExpandable;
