enum ActionTypes {
  LOGIN_FAILURE = 'gofre/auth/LOGIN_FAILURE',
  LOGIN_REQUEST = 'gofre/auth/LOGIN_REQUEST',
  LOGIN_SUCCESS = 'gofre/auth/LOGIN_SUCCESS',
  LOGIN_USING_TOKEN = 'gofre/auth/LOGIN_USING_TOKEN',
  LOGOUT = 'gofre/auth/LOGOUT',
  REGISTER_BUSINESS_FAILURE = 'gofre/auth/REGISTER_BUSINESS_FAILURE',
  REGISTER_BUSINESS_REQUEST = 'gofre/auth/REGISTER_BUSINESS_REQUEST',
  REGISTER_BUSINESS_SUCCESS = 'gofre/auth/REGISTER_BUSINESS_SUCCESS',
  REGISTER_FROM_LEAD_FAILURE = 'gofre/auth/REGISTER_FROM_LEAD_FAILURE',
  REGISTER_FROM_LEAD_REQUEST = 'gofre/auth/REGISTER_FROM_LEAD_REQUEST',
  REGISTER_FROM_LEAD_SUCCESS = 'gofre/auth/REGISTER_FROM_LEAD_SUCCESS',
  REGISTER_FAILURE = 'gofre/auth/REGISTER_FAILURE',
  REGISTER_REQUEST = 'gofre/auth/REGISTER_REQUEST',
  REGISTER_SUCCESS = 'gofre/auth/REGISTER_SUCCESS',
  LOGIN_BUSINESS_FAILURE = 'gofre/auth/LOGIN_BUSINESS_FAILURE',
  LOGIN_BUSINESS_REQUEST = 'gofre/auth/LOGIN_BUSINESS_REQUEST',
  LOGIN_BUSINESS_SUCCESS = 'gofre/auth/LOGIN_BUSINESS_SUCCESS',
  SET_SCOPES = 'gofre/auth/SET_SCOPES',
  CLEAR_REGISTER_ERROR = 'gofre/auth/CLEAR_REGISTER_ERROR',
  CREATE_LEAD_FAILURE = 'gofre/auth/CREATE_LEAD_FAILURE',
  CREATE_LEAD_REQUEST = 'gofre/auth/CREATE_LEAD_REQUEST',
  CREATE_LEAD_SUCCESS = 'gofre/auth/CREATE_LEAD_SUCCESS',
}

export enum UserPermissions {
  SUBSCRIPTION = 'subscription',
  SUBSCRIPTION_BASE = 'subscription::base',
  SUBSCRIPTION_OWNER = 'subscription::owner',
  SUBSCRIPTION_ADMIN = 'subscription::admin',
  SUBSCRIPTION_EDITOR = 'subscription::editor',
  CONTACTS_CREATE = 'contacts::create',
  RECURRENT_CREATE = 'document-recurrents::create',
  PRODUCTS_CREATE = 'products::create',
  PRODUCTS_STOCK = 'product-stock::enable',
  USERS_CREATE = 'users::create',
  OCR_SCANS = 'ocr::scan',
  REMITTANCES_CREATE = 'remittances::create',
  EXPENSES_RECURRENT_CREATE = 'expenses-recurrents::create',
  PAYMENT_REMINDERS_CREATE = 'reminders::create',
}

export default ActionTypes;
