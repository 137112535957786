import IconByString, { IconByStringProps } from './Icon/IconType/IconByString';

const IconPlaneSend = (props: IconByStringProps) => {
  const { ...rest } = props;
  return (
    <IconByString {...rest}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22 18a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm1 0a4 4 0 1 1-8 0 4 4 0 0 1 8 0Zm-3.5-.5V16h-1v2.5H21v-1h-1.5Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.008 17.725c.019-.357.075-.703.166-1.035l-3.438-1.719 9.813-9.812-.984 7.872c.338.039.666.11.98.212L21.827 3 1 11.22l8.226 4.114v5.44a.5.5 0 0 0 .876.33l3.245-3.71.66.33Zm-3.782-1.891 2.197 1.099-2.197 2.511v-3.61Zm8.943-10.71L9.794 14.5 3.45 11.328 19.17 5.124Z"
        fill="currentColor"
      />
    </IconByString>
  );
};

export default IconPlaneSend;
