import { FC } from 'react';
import { Hidden } from 'react-grid-system';

import useTranslations from 'modules/I18n/hooks/useTranslations';
import { Dialog, MODAL_WIDTH_MEDIUM, useCloseModal } from 'modules/Modals';
import { clearMailTexts } from 'modules/PaymentReminders/helpers/mailTexts';
import useCreatePaymentReminder from 'modules/PaymentReminders/hooks/useCreatePaymentReminder';
import { Button, Warning } from 'modules/Ui';
import { WarningType } from 'modules/Ui/Warning/Warning';

import { messages } from '../../messages';
import { ToneEnum } from '../../models/paymentReminder';
import { friendlyContent } from '../content';
import PaymentReminderForm, { FormValues } from '../PaymentReminderForm';
import { AddPaymentReminderModalProps } from './AddPaymentReminderModalProps';

const AddPaymentReminderModal: FC<AddPaymentReminderModalProps> = () => {
  const closeModal = useCloseModal();
  const { t } = useTranslations();

  const { mutate } = useCreatePaymentReminder();

  const onSubmit = async (values: FormValues) => {
    const daysFromValues = values.days || 0;
    const { when, body, subject, tone } = values;
    const days = when === 'before' ? -daysFromValues : daysFromValues;

    await mutate({
      subject: clearMailTexts(subject),
      body: clearMailTexts(body),
      days,
      tone,
    });

    closeModal();
  };
  return (
    <Dialog
      id="add-payment-reminder-modal"
      title={t(messages.title)}
      type="fullScreen"
      underlayMarginTop="66px"
      onClose={closeModal}
      buttonPrimary={
        <Button type="submit" form="addPaymentReminderForm" variant="secondary">
          {t(messages.accept)}
        </Button>
      }
      buttonSecondary={
        <Hidden xs>
          <Button onClick={closeModal} variant="stroke">
            {t(messages.cancel)}
          </Button>
        </Hidden>
      }
      dialogWidth={{
        sm: `calc(${MODAL_WIDTH_MEDIUM} - 32px)`,
        md: MODAL_WIDTH_MEDIUM,
      }}
    >
      <Warning
        type={WarningType.INFO}
        message={t(messages.info)}
        iconSize={24}
        messageFontSize={14}
        messageLineHeight={19}
      />
      <PaymentReminderForm
        formId="addPaymentReminderForm"
        onSubmit={onSubmit}
        formValues={{
          tone: ToneEnum.FRIENDLY,
          when: 'before',
          subject:
            'Factura ##NumeroDocumento##{NumeroDocumento} de parte de ##NombreFiscalEmisor##{NombreFiscalEmisor}',
          body: friendlyContent,
        }}
      />
    </Dialog>
  );
};

export default AddPaymentReminderModal;
