import * as Yup from 'yup';

import { TranslationFunction } from 'modules/I18n/hooks/useTranslations';

import { messages } from '../messages';

const getValidationSchema = (t: TranslationFunction) =>
  Yup.object().shape({
    body: Yup.string().trim().required(t(messages.requiredField)),
    subject: Yup.string().trim().required(t(messages.requiredField)),
    days: Yup.number()
      .required(t(messages.required))
      .max(100, t(messages.maxDays)),
  });

export default getValidationSchema;
