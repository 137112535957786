export enum AnalyticsEvent {
  SINGUP = 'Signup',
  LOGIN = 'Login',
  LOGOUT = 'Logout',
  NOTIFY = 'Notification Viewed',
  PRODUCT_LIST = 'Product List Viewed',
  PRODUCT_CLICK = 'Product Clicked',
  FORM_ERROR = 'Form Error',
  CHECKOUT_START = 'Checkout Started',
  CHECKOUT_STEP_VIEWED = 'Checkout Step Viewed',
  CHECKOUT_STEP_COMPLETED = 'Checkout Step Completed',
  ORDER_COMPLETED = 'Order Completed',
  ORDER_UPGRADE = 'Order Upgrade',
  ORDER_DOWNGRADE = 'Order Downgrade',
  ORDER_CANCELLED = 'Order Cancelled',
  COUPON_ENTERED = 'Coupon Entered',
  COUPON_APPLIED = 'Coupon Applied',
  COUPON_DENIED = 'Coupon Denied',
  COUPON_REMOVED = 'Coupon Removed',
  OCR_SCAN_CLICK = 'OCR scan button Clicked',
  ADD_LOGO_BANNER_CLICK = 'Add logo banner Clicked',
  ADD_STOCK_BANNER_CLICK = 'Add stock banner Clicked',
  ADD_LOGO_CONFIG_TEMPLATES = 'Add logo in config templates',
  MODAL_OPEN = 'Modal open',
  MODAL_CLOSE = 'Modal close',
  MODAL_CLICKED = 'Modal clicked',
  DOCUMENT_SENT = 'Document has been sent',
  DOWNLOAD_ELECTRONIC_DOCUMENT = 'Downloaded electronic document successfully',
  DOWNLOAD_SOFTWARE_KIT = 'Download software kit in bookkeeper',
  SHOPIFY_NOTIFICATION_VIEWED = 'Shopify notification viewed',
  APP_SHOW_PLANS = 'App show plans',
  BOOKKEEPER_GUIDE_DOWNLOAD = 'Bookkeeper guide download',
  SEE_REPORTS_AND_GRAPHICS = 'See reports and graphics',
  ACTIVATE_TRACKING = 'Activate traking',
  LEAD_FORM_SUBMITTED = 'Lead Form Submitted',
  REPORT_BANNER_UPDATE_PLAN = 'update plan in Contact report banner',
  CREATE_DELIERY_NOTE = 'Create delivery note',
  BOOKKEEPER_LOG_INTO_BUSINESS = 'Bookkeeper log into business',
  REMITTANCE_BAIT_UPDATE_PLAN = 'Unlimited plan in the bait view of the remittance list',
  RECURRENT_CREATE_UPDATE_PLAN = 'Unlimited plan in recurrent create view',
  REMITTANCE_FROM_DOCUMENTS = 'Remittance from documents',
  REMITTANCE_FROM_EXPENSES = 'Remittance from expenses',
  PRODUCT_BAIT_UPDATE_PLAN = 'Product bait update plan',
  FIRST_DOCUMENT_CREATED = 'First Document Created',
  COMERCIAL_NOTIFICATION_CTA_CLICKED = 'Comercial notification CTA clicked',
  CONFIGURE_PAYMENT_REMINDERS = 'Configure payment reminders',
  UPGRADE_PAYMENT_REMINDER = 'Upgrade plan to get payment reminder',
}
export interface AnalyticsFormError {
  formName: string;
  field: string;
  error: string;
}

export interface AnalyticsProduct {
  product_id: string;
  sku: string;
  category: string;
  name: string;
  brand: string;
  variant: string;
  price: number;
  quantity: number;
  coupon: string;
  position: number;
  url: string;
  image_url: string;
}

export interface AnalyticsCheckoutStep {
  checkout_id: string;
  step: number;
  shipping_method: string;
  payment_method: string;
}

export interface AnalyticsCheckout {
  order_id: string;
  affiliation: string;
  value?: number;
  revenue: number;
  shipping: number;
  tax: number;
  discount: number;
  coupon: string;
  currency: string;
  products: AnalyticsProduct[];
}

export interface AnalyticsOrder extends AnalyticsCheckout {
  checkout_id?: string;
  total: number;
  subtotal?: number;
}

export interface AnalyticsAddress {
  street: string;
  postalCode: string;
  state: string;
  country: string;
  city: string;
}
