import { FC } from 'react';

import { UserPermissions } from 'modules/Auth/constants';
import useTranslations from 'modules/I18n/hooks/useTranslations';
import { Dialog } from 'modules/Modals';
import { PLANS } from 'modules/Subscriptions/models';
import { ScopeTypes } from 'modules/Subscriptions/services/changePlan/changePlan';
import { Button, Text } from 'modules/Ui';

import messages from '../messages';

export interface Props {
  onClose(): void;
  failedScopes?: ScopeTypes[];
  warningScopes?: ScopeTypes[];
  selected: string;
  error?: string;
}

function errorMessageKey(failedScopes?: ScopeTypes[], error?: string) {
  const productError =
    failedScopes?.length === 1 &&
    failedScopes.includes(UserPermissions.PRODUCTS_CREATE);
  const recurrentExpensesError =
    failedScopes?.length === 1 &&
    failedScopes.includes(UserPermissions.EXPENSES_RECURRENT_CREATE);
  const recurrentError = failedScopes?.includes(
    UserPermissions.RECURRENT_CREATE
  );
  const productStockError = failedScopes?.includes(
    UserPermissions.PRODUCTS_STOCK
  );
  const reminderError = failedScopes?.includes(
    UserPermissions.PAYMENT_REMINDERS_CREATE
  );

  if (error === 'wrong-shopify-plan-compatibility') {
    return messages.shopifyTitle;
  }
  if (error === 'wrong-api-public-plan-compatibility') {
    return messages.apiPublicTitle;
  }
  if (productError) {
    return messages.planProductsNotAllowed;
  }
  if (recurrentError) {
    return messages.recurrents;
  }
  if (recurrentExpensesError) {
    return messages.recurrentExpenses;
  }
  if (productStockError) {
    return messages.productStockEnabled;
  }
  if (reminderError) {
    return messages.paymentRemindersNotAllowed;
  }
  return messages.planNotAllowed;
}

const CreatePlanErrorDialog: FC<Props> = ({
  onClose,
  failedScopes,
  warningScopes,
  selected,
  error,
}) => {
  const { t } = useTranslations();

  const hasSelectedBasic = PLANS.BASIC.includes(selected);
  let text = '';
  if (hasSelectedBasic) {
    text = t(messages.planNotAllowedBasic);
  } else {
    text = t(messages.planNotAllowedPro);
  }
  if (error === 'wrong-shopify-plan-compatibility') {
    text = t(messages.shopifySubtitle);
  }
  if (error === 'wrong-api-public-plan-compatibility') {
    text = t(messages.apiPublicSubtitle);
  }
  const hasIntegrationsError =
    error === 'wrong-shopify-plan-compatibility' ||
    error === 'wrong-api-public-plan-compatibility';
  const genericError =
    (failedScopes?.length && failedScopes.length > 1) ||
    (failedScopes?.length && warningScopes?.length) ||
    (hasIntegrationsError && warningScopes?.length);

  if (genericError) {
    text = t(messages.genericScopeError);
  }

  const subtitle = genericError
    ? undefined
    : t(errorMessageKey(failedScopes, error));

  return (
    <Dialog
      id="createPlanErrorModal"
      onClose={onClose}
      title={t(messages.title)}
      buttonPrimary={
        <Button
          data-testid="createPlanErrorModal-accept"
          variant="secondary"
          onClick={onClose}
        >
          {t(messages.accept)}
        </Button>
      }
      subtitle={subtitle}
    >
      <Text lineHeight={22}>{text}</Text>
    </Dialog>
  );
};

export default CreatePlanErrorDialog;
