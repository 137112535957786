import { css } from 'modules/Theme/styled-components';

type Props = {
  open?: boolean;
};

const expandableAnimation = css<Props>`
  @supports (content-visibility: visible) {
    content-visibility: ${({ open }) => (open ? 'visible' : 'hidden')};
  }

  @supports not (content-visibility: visible) {
    display: ${({ open }) => (open ? 'block' : 'none')};
  }

  @media (prefers-reduced-motion: no-preference) {
    transition: opacity 0.25s,
                height 0.30s ease,
                content-visibility 0.40s;
    @supports (transition-behavior: allow-discrete) {
    /* stylelint-disable-next-line property-no-unknown */
      transition-behavior: allow-discrete;
    }
  }

  @media (prefers-reduced-motion: reduce) {
    transition: none;
  }
`;

export default expandableAnimation;
