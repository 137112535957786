import { createElement } from 'react';

import InternalLink from 'modules/Ui/Link/LinkType/InternalLink';
import Text from 'modules/Ui/Text';

import {
  BasicLinkAttrs,
  LinkOptions,
  FormatHelper,
  TextOptions,
  HelperInternalLinkProps,
} from './types';

export const formatLinkFromMarkdown = (input: string): BasicLinkAttrs => {
  const regex = /\[([^\]]+)\]\(([^)]+)\)/;
  const matches = input.match(regex);
  return matches
    ? { text: matches[1], href: matches[2] }
    : { text: '', href: '' };
};

const link = (options?: LinkOptions) => (chunk: string[]) => {
  const { secure, ...rest } = options || {};
  const linkAttrs = formatLinkFromMarkdown(chunk[0]);
  if (secure) {
    linkAttrs.data = { href: linkAttrs.href };
    linkAttrs.href = '#';
  }
  return createElement('a', {
    children: linkAttrs.text,
    ...linkAttrs,
    ...rest,
  });
};

const internalLink =
  (options: HelperInternalLinkProps) => (chunk: string[]) => {
    return createElement(InternalLink, { children: chunk, ...options });
  };

const text = (options: TextOptions) => (chunk: string[]) => {
  return createElement(Text, { ...options, children: chunk });
};

const download = (options?: LinkOptions) => link({ ...options });

const secureDownload = (options?: LinkOptions) =>
  download({ ...options, secure: true });

const bold = (options?: TextOptions) =>
  text({ ...options, tag: 'b', fontWeight: '600', color: 'gray800' });

export const formatHelpers: FormatHelper = {
  br: () => createElement('br'),
  link: link(),
  download: download(),
  downloadExcel: download({ type: 'application/vnd.ms-excel' }),
  internalLink,
  secureDownload,
  bold,
};
