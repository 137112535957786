import { useInvalidateAndUpdate } from 'modules/App/hooks/useInvalidateAndUpdate';
import useNotifications from 'modules/Notifications/hooks/useNotifications';
import { ResponseError } from 'types/response-error.type';
import requestErrorHandler from 'utils/requestErrorHandler';
import { useBlockingMutation } from 'utils/useBlockingMutation';

import { PaymentReminder } from '../models/paymentReminder';
import createPaymentReminder, {
  invalidateQueries,
} from '../services/createPaymentReminder';
import messages from './messages';

function useCreatePaymentReminder() {
  const notifications = useNotifications();
  const invalidateAndUpdate = useInvalidateAndUpdate();

  const { mutate } = useBlockingMutation(createPaymentReminder, {
    onSuccess: async (response: PaymentReminder): Promise<PaymentReminder> => {
      invalidateAndUpdate({
        invalidateQueries,
      });
      notifications.success(messages.createSuccess.id, { ttl: 5000 });
      return response;
    },
    onError: (error: ResponseError) => {
      const { isNetworkError, networkErrorTranslationKey } =
        requestErrorHandler(error);

      return notifications.error(
        isNetworkError ? networkErrorTranslationKey : messages.createError.id
      );
    },
  });
  return { mutate };
}

export default useCreatePaymentReminder;
