export enum ToneEnum {
  FRIENDLY = 'friendly',
  FORMAL = 'formal',
  ANGRY = 'angry',
}
export interface PaymentReminder {
  active?: boolean;
  subject: string;
  body: string;
  days: number;
  id: string;
  tone?: ToneEnum;
}
