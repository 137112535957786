import {
  FlexboxProps,
  LayoutProps,
  SpaceProps,
  ColorProps,
  TypographyProps,
} from 'styled-system';

import Box from 'modules/Ui/Box';

import Text from '../../Text';
import Card from './commons/Card';
import Input from './commons/Input';
import Radio from './commons/Radio';
import Wrapper from './commons/Wrapper';

export interface RadioButtonCardProps
  extends FlexboxProps,
    LayoutProps,
    SpaceProps,
    ColorProps,
    TypographyProps {
  checked?: boolean;
  children?: React.ReactNode;
  disabled?: boolean;
  error?: string;
  id: string;
  name: string;
  text: string;
  onChange?: (value: any) => void;
  onFocus?: (event: any) => void;
  onClick?: (value: any) => void;
  innerPadding?: string;
  required?: boolean;
  value: any;
  radioIsVisible?: boolean;
  textIsVisible?: boolean;
}

const RadioButtonCard = (props: RadioButtonCardProps) => {
  const {
    checked,
    children,
    color,
    disabled,
    error,
    id,
    innerPadding,
    name,
    onChange,
    onClick,
    radioIsVisible = true,
    required,
    text,
    textIsVisible = true,
    value,
    ...rest
  } = props;
  const defaultColor = disabled ? 'gray400' : 'gray600';
  return (
    <Wrapper
      alignItems="center"
      color={color || defaultColor}
      display={textIsVisible ? 'flex' : 'initial'}
      htmlFor={id}
      innerPadding={innerPadding}
      position="relative"
      {...{ disabled, onClick, ...rest }}
    >
      {!textIsVisible && (
        <Box tag="span" accessibleHidden>
          {text}
        </Box>
      )}
      <Input
        {...{ checked, disabled, id, name, onChange, value, ...rest }}
        aria-invalid={error ? true : undefined}
        type="radio"
        aria-labelledby={`${id}-label`}
        data-testid={`${id}-radio`}
        required={required}
        error={error}
      />
      <Card
        alignItems={textIsVisible ? 'center' : ''}
        backgroundColor="gray0"
        borderColor={error ? 'error' : 'gray300'}
        borderRadius="8px"
        borderStyle="solid"
        borderWidth="1px"
        boxSizing={textIsVisible ? 'border-box' : ''}
        className="radioCard"
        disabled={disabled}
        display={!textIsVisible ? 'block' : 'flex'}
        fontSize="inherit"
        lineHeight="inherit"
        padding={innerPadding || { _: '0 14px', sm: '0 24px' }}
        position={textIsVisible ? 'relative' : ''}
        tag="span"
        width={textIsVisible ? '100%' : ''}
      >
        {radioIsVisible && (
          <Radio
            alignItems="center"
            borderRadius="50%"
            borderStyle="solid"
            borderWidth="1px"
            disabled={disabled}
            display="flex"
            error={error}
            flex="0 1 auto"
            height="20px"
            justifyContent="center"
            minWidth="20px"
            position="relative"
            tag="span"
            width="20px"
          />
        )}
        {textIsVisible && (
          <Text
            aria-hidden="true"
            className="radioCard-text"
            color={error ? 'error' : 'inherit'}
            flex="1"
            fontSize="inherit"
            fontWeight="inherit"
            id={`${id}-label`}
            lineHeight="inherit"
            marginLeft={{ _: '8px' }}
            position="relative"
            tag="span"
          >
            {text}
          </Text>
        )}
        {children}
      </Card>
    </Wrapper>
  );
};

export default RadioButtonCard;
