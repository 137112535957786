import { AxiosResponse } from 'axios';

import { getConfig } from 'modules/App/config';
import { PAYMENT_REMINDERS } from 'modules/App/queries/queries';
import request from 'utils/request';

import { PaymentReminder } from '../models/paymentReminder';

const createPaymentReminder = async (
  data: Omit<PaymentReminder, 'id' | 'active'>
) => {
  const config = await getConfig();

  const endpoint = config.apiPaymentRemindersService;
  const response = await request<AxiosResponse<PaymentReminder>>(endpoint, {
    method: 'POST',
    data,
  });

  return response.data.data;
};

export const invalidateQueries = [PAYMENT_REMINDERS];

export default createPaymentReminder;
