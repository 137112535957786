import { defineMessages } from 'react-intl';

const messages = defineMessages({
  detail: { id: 'gofre.Document.detail.info.title' },
  detailReceipt: { id: 'gofre.Document.detail.info.title-receipt' },
  detailTest: { id: 'gofre.Document.detail.info.title-test' },
  detailProforma: { id: 'gofre.Document.detail.info.title-proforma' },
  detailDeliveryNote: {
    id: 'gofre.Document.detail.info.title-delivery-note',
  },
  detailCorrective: { id: 'gofre.Document.detail.info.title-corrective' },
  detailSubtitute: { id: 'gofre.Document.detail.info.title-sustitute' },
  detailQuote: { id: 'gofre.Document.detail.info.title-quote' },
  issued: { id: 'gofre.Document.Detail.tag.issued' },
  draft: { id: 'gofre.Document.Detail.tag.draft' },
  document: { id: 'gofre.Document.Detail.document' },
  receipt: { id: 'gofre.Documents.List.List.Issued.Header.receipt' },
  corrective: { id: 'gofre.Document.detail.info.corrective' },
  receiptCorrective: { id: 'gofre.Document.detail.info.corrective.receipt' },
  corrected: { id: 'gofre.Document.detail.info.corrected' },
  correctiveDescription: { id: 'gofre.Document.detail.description.corrective' },
  replaced: { id: 'gofre.Document.detail.info.replaced' },
  replacedDescription: { id: 'gofre.Document.detail.description.replaced' },
  sustitute: { id: 'gofre.Document.detail.info.sustitute' },
  sustituteDescription: { id: 'gofre.Document.detail.description.sustitute' },
  noContact: { id: 'gofre.Document.no-contact' },
});

export const tracking = defineMessages({
  seeAll: { id: 'gofre.Document.detail.document-tracking.see-all' },
  title: { id: 'gofre.Document.detail.document-tracking.title' },
  error: { id: 'gofre.Document.detail.document-tracking.error' },
});

export const modal = defineMessages({
  accept: { id: 'gofre.Document.detail.document-tracking.Modal.accept' },
  title: { id: 'gofre.Document.detail.document-tracking.Modal.title' },
  error: {
    id: 'gofre.Document.detail.document-tracking.Modal.data-electornic.error',
  },
});

export const documentElectronicStatusMessages = defineMessages({
  title: { id: 'gofre.Document.detail.digital.title' },
  pendingDetail: { id: 'gofre.Document.detail.digital.pending-detail' },
  errorDetail: {
    id: 'gofre.Document.detail.digital.error-detail',
  },
  presentedWithMessages: {
    id: 'gofre.Document.detail.digital.presented-with-messages',
  },
});

export const digitalDocumentResultStatusMessages = defineMessages({
  rejected: { id: 'gofre.Document.detail.digital.status.rejected' },
  rejectedWithLink: {
    id: 'gofre.Document.detail.digital.status.rejectedWithLink',
  },
  presentedWithErrors: {
    id: 'gofre.Document.detail.digital.status.presented-with-errors',
  },
  pending: {
    id: 'gofre.Document.detail.digital.status.pending',
  },
  modalAcceptButton: {
    id: 'gofre.Document.detail.digital.status.errors-modal.accept-button',
  },
  modalDescText: {
    id: 'gofre.Document.detail.digital.status.errors-modal.desc.text',
  },
  modalTitle: {
    id: 'gofre.Document.detail.digital.status.errors-modal.title',
  },
  modalDescTextRejected: {
    id: 'gofre.Document.detail.digital.status.rejected-modal.desc.text',
  },
  modalTitleRejected: {
    id: 'gofre.Document.detail.digital.status.rejected-modal.title',
  },
});

export const documentRectifyModalMessages = defineMessages({
  title: { id: 'gofre.Document.detail.rectify-modal.title' },
  totalTitle: { id: 'gofre.Document.detail.rectify-modal.total.title' },
  totaldescription: {
    id: 'gofre.Document.detail.rectify-modal.total.description',
  },
  partialTitle: { id: 'gofre.Document.detail.rectify-modal.partial.title' },
  partialDescription: {
    id: 'gofre.Document.detail.rectify-modal.partial.description',
  },
  emitRectification: {
    id: 'gofre.Document.detail.rectify-modal.emit-rectification',
  },
  rectifyDocument: {
    id: 'gofre.Document.detail.rectify-modal.rectify-document',
  },
});

export const newFeatureBanner = defineMessages({
  title: { id: 'gofre.newFeatureBanner.title' },
  subtitle: { id: 'gofre.newFeatureBanner.subtitle' },
});

export const documentPaymentReminderMessages = defineMessages({
  warningText: { id: 'gofre.Document.detail.payment-reminder.warning-text' },
  linkText: { id: 'gofre.Document.detail.payment-reminder.link-text' },
  title: { id: 'gofre.Document.detail.payment-reminder.title' },
  noPaymentsRemindersTitle: {
    id: 'gofre.Document.detail.payment-reminder.no-payments-reminders.title',
  },
  noPaymentsRemindersDescription: {
    id: 'gofre.Document.detail.payment-reminder.no-payments-reminders.description',
  },
  noRemindersActiveMessage: {
    id: 'gofre.Document.detail.payment-reminder.no-reminders-active.message',
  },
  noPaymentsRemindersLinkText: {
    id: 'gofre.Document.detail.payment-reminder.no-payments-reminders.link-text',
  },
});

export default messages;
