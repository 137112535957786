import { getConfig } from 'modules/App/config';
import { DataTableSortOrder } from 'modules/Ui/DataTable/DataTable.models';
import { buildTortillaBaseQuery } from 'utils/query/buildTortillaBaseQuery';
import request from 'utils/request';

import { PaymentReminder } from '../models/paymentReminder';

export default async function fetchPaymentReminders() {
  const endpoint = (await getConfig()).apiPaymentRemindersService;

  const query = buildTortillaBaseQuery({
    page: 0,
    pageSize: 50,
    sort: [
      {
        field: 'createdAt',
        order: DataTableSortOrder.DESC,
      },
    ],
  });

  const response = await request<any>(`${endpoint}?${query}`, {
    method: 'GET',
  });
  return response?.data?.data as {
    items: PaymentReminder[];
    count: number;
  };
}
