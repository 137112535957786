import { SuggestionsFields } from 'modules/Documents/Send/hooks/useSuggestionsData';

export const clearMailTexts = (text: string) => {
  return text
    .replaceAll(`##${SuggestionsFields.NombreCliente}##`, '{{contactName}}')
    .replaceAll(`##${SuggestionsFields.NumeroDocumento}##`, '{{identifier}}')
    .replaceAll(`##${SuggestionsFields.NombreFiscalEmisor}##`, '{{fiscalName}}')
    .replaceAll(`##${SuggestionsFields.FechaVencimiento}##`, '{{dueDate}}')
    .replaceAll(`{${SuggestionsFields.NombreCliente}}`, '')
    .replaceAll(`{${SuggestionsFields.NumeroDocumento}}`, '')
    .replaceAll(`{${SuggestionsFields.NombreFiscalEmisor}}`, '')
    .replaceAll(`{${SuggestionsFields.FechaVencimiento}}`, '');
};

export const revertMailTexts = (text: string) => {
  return text
    .replaceAll(
      '{{contactName}}',
      `##${SuggestionsFields.NombreCliente}##{NombreCliente}`
    )
    .replaceAll(
      '{{identifier}}',
      `##${SuggestionsFields.NumeroDocumento}##{NumeroDocumento}`
    )
    .replaceAll(
      '{{fiscalName}}',
      `##${SuggestionsFields.NombreFiscalEmisor}##{NombreFiscalEmisor}`
    )
    .replaceAll(
      '{{dueDate}}',
      `##${SuggestionsFields.FechaVencimiento}##{FechaVencimiento}`
    );
};
