import { FC, ReactNode } from 'react';

import { useField } from 'formik';

import RadioButtonCard from 'modules/Ui/Form/RadioButtonCard';

export interface ToneFieldProps {
  children?: ReactNode;
  id: string;
  onChange?: (value: any) => void;
  text: string;
  value: any;
}

export const ToneField: FC<ToneFieldProps> = ({
  children,
  onChange,
  id,
  text,
  value,
  ...props
}) => {
  const [field, , helpers] = useField('tone');
  const isChecked = field.value === value;
  const handleChange = () => {
    onChange?.(value);
    helpers.setValue(value);
  };
  return (
    <RadioButtonCard
      {...field}
      checked={isChecked}
      id={id}
      onClick={handleChange}
      radioIsVisible={false}
      text={text}
      textIsVisible={false}
      value={value}
      {...props}
    >
      {children}
    </RadioButtonCard>
  );
};
