import { useSelector } from 'react-redux';

import { selectCurrentBusiness } from 'modules/Business/Current/selectors';
import { Document } from 'modules/Documents/models/document';
import { formatDate } from 'utils/dates';

export enum SuggestionsFields {
  NombreCliente = 'NombreCliente',
  NumeroDocumento = 'NumeroDocumento',
  NombreFiscalEmisor = 'NombreFiscalEmisor',
  FechaVencimiento = 'FechaVencimiento',
}

export enum SuggestionsFieldsType {
  name = 'contactName',
  invoiceNumber = 'invoiceNumber',
  fiscalName = 'fiscalName',
  dueDate = 'dueDate',
}

type SuggestionMapType = {
  [key: string]: string;
};

export const SuggestionMap: SuggestionMapType = {
  [SuggestionsFields.NombreCliente]: SuggestionsFieldsType.name,
  [SuggestionsFields.NumeroDocumento]: SuggestionsFieldsType.invoiceNumber,
  [SuggestionsFields.NombreFiscalEmisor]: SuggestionsFieldsType.fiscalName,
  [SuggestionsFields.FechaVencimiento]: SuggestionsFieldsType.dueDate,
};

export default function useSuggestionsData(document: Document) {
  const { fiscalName } = useSelector(selectCurrentBusiness);

  const data = [
    {
      id: document.contact.fiscalName,
      display: SuggestionsFields.NombreCliente,
    },
    {
      id: document.identifier,
      display: SuggestionsFields.NumeroDocumento,
    },
    {
      id: fiscalName,
      display: SuggestionsFields.NombreFiscalEmisor,
    },
  ];

  if (document.dueDate) {
    data.push({
      id: formatDate(document.dueDate),
      display: SuggestionsFields.FechaVencimiento,
    });
  }

  return data;
}
