import { FC } from 'react';
import { MentionsInput, Mention } from 'react-mentions';

import { useField } from 'formik';
import { LayoutProps, FlexboxProps, SpaceProps } from 'styled-system';

import useTheme from 'modules/Theme/hooks/useTheme';
import styled from 'modules/Theme/styled-components';

import Box from '../../../Box';
import defaultStyles from './defaultStyles';

export interface SuggestionsData extends LayoutProps, FlexboxProps, SpaceProps {
  id: string;
  display: string;
}

interface SuggestionsInputProps {
  data: SuggestionsData[];
  singleLine?: boolean;
  id: string;
  maxLength?: number;
  rows?: number;
}

const StyledWrapper = styled(Box)`
  transition: border 0.2s ease-in;

  &:focus-within {
    border: 1px solid ${(props) => props.theme.colors.brand500};
  }

  border: 1px solid ${(props) =>
    props.error ? props.theme.colors.error : props.theme.colors.primary200};
`;

const StyledMentionsInput = styled(MentionsInput)`
  ul {
    li:last-child {
      border-bottom: none !important;
    }
  }

  textarea[class*="__input"],
  input[class*="__input"] {
    &:hover,
    &:focus {
       border-radius: 0;
       outline: none;
     }
   }
`;

export const SuggestionsInput: FC<SuggestionsInputProps> = ({
  data,
  id,
  maxLength = 200,
  rows,
  singleLine = true,
}) => {
  const [field, meta, helpers] = useField(id);

  const theme = useTheme();
  return (
    <StyledWrapper padding="0 8px" error={meta.touched && meta.error}>
      <StyledMentionsInput
        id={id}
        maxLength={maxLength}
        onChange={(_, newValue) => {
          helpers.setValue(newValue);
        }}
        singleLine={singleLine}
        style={defaultStyles}
        value={field.value}
        rows={rows}
      >
        <Mention
          trigger="#"
          className="mention"
          data={data}
          markup="##__display__##{__id__}"
          appendSpaceOnAdd
          displayTransform={(idData) => idData}
          renderSuggestion={(_suggestion, _search, highlightedDisplay) => {
            return <Box tag="span">{highlightedDisplay}</Box>;
          }}
          style={{
            backgroundColor: theme.colors.brand200A60,
          }}
        />
      </StyledMentionsInput>
    </StyledWrapper>
  );
};

export default SuggestionsInput;
