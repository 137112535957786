import { ReactNode } from 'react';

import { expandableAnimation } from 'modules/Theme/mixins';
import styled from 'modules/Theme/styled-components';
import Box from 'modules/Ui/Box';

interface ExpandedContentProps {
  content: ReactNode;
  open: boolean;
}

export const StyledTransitionExpandable = styled(Box)<ExpandedContentProps>`
  ${expandableAnimation}
`;

export const ExpandedContent = ({ content, open }: ExpandedContentProps) => {
  return (
    <StyledTransitionExpandable
      className="expandable__content"
      height={open ? 'auto' : '0'}
      opacity={open ? '1' : '0'}
      open={open}
      willChange={open ? 'height, content-visibility' : ''}
    >
      {content}
    </StyledTransitionExpandable>
  );
};
